import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  Typography,
  Chip,
  Alert,
  Badge,
  Divider,
  Paper
} from '@mui/material';
import { ListTodo, ExternalLink, Clock } from 'lucide-react';
import { format } from 'date-fns';
import axiosInstance from '../../utils/axiosConfig';

interface TaskboardSubtask {
  _id: string;
  title: string;
  completed: boolean;
  assignedTo: string | null;
  dueDate: string;
  completedAt?: string;
  completedBy?: string;
  boardId?: string;
  boardTitle?: string;
}

interface Taskboard {
  _id: string;
  title: string;
  description: string;
  status: 'todo' | 'in_progress' | 'blocked' | 'done';
  priority: 'low' | 'medium' | 'high' | 'urgent';
  progress: number;
  category: string;
  dueDate: string;
  estimatedHours?: number;
  subtasks: TaskboardSubtask[];
  assignedTo: string | { _id: string; name: string; email: string; };
  watchers: string[];
  createdBy: { _id: string; name: string; email: string; };
  lastUpdatedBy: string;
  order: number;
}

interface TaskboardApiResponse {
  tasks: Taskboard[];
  pagination: {
    total: number;
    page: number;
    pages: number;
  };
}

interface TaskboardListState {
  taskboards: Taskboard[];
  userSubtasks: TaskboardSubtask[];
  loading: boolean;
  error: string | null;
}

interface TaskboardListProps {
  userId: string;
}

const TaskboardList: React.FC<TaskboardListProps> = ({ userId }) => {
  const navigate = useNavigate();
  const [expanded, setExpanded] = useState(false);
  const [state, setState] = useState<TaskboardListState>({
    taskboards: [],
    userSubtasks: [],
    loading: true,
    error: null
  });

  useEffect(() => {
    const fetchTaskboards = async () => {
      try {
        console.log('Fetching taskboards for user:', userId);
        const response = await axiosInstance.get<TaskboardApiResponse>('/api/taskboards', {
          params: {
            assignedTo: userId,
          }
        });
        
        console.log('Taskboards response:', response.data);

        const taskboardsArray = response.data.tasks;

        // Process subtasks, including those from tasks assigned to the user
        const subtasks: TaskboardSubtask[] = taskboardsArray.flatMap((taskboard: Taskboard) => {
          const isTaskAssigned = typeof taskboard.assignedTo === 'string' 
            ? taskboard.assignedTo === userId
            : taskboard.assignedTo?._id === userId;

          return (taskboard.subtasks || [])
            .filter((subtask: TaskboardSubtask) => {
              const isSubtaskAssigned = subtask.assignedTo === userId;
              return (isSubtaskAssigned || isTaskAssigned) && !subtask.completed;
            })
            .map((subtask: TaskboardSubtask) => ({
              ...subtask,
              boardId: taskboard._id,
              boardTitle: taskboard.title
            }));
        });
        
        setState({
          taskboards: taskboardsArray,
          userSubtasks: subtasks,
          loading: false,
          error: null
        });
      } catch (err) {
        console.error('Error fetching taskboards:', err);
        setState(prev => ({
          ...prev,
          error: 'Failed to load taskboards',
          loading: false
        }));
      }
    };

    fetchTaskboards();
  }, [userId]);

  const getStatusColor = (status: string) => {
    switch (status) {
      case 'todo':
        return 'default';
      case 'in_progress':
        return 'primary';
      case 'blocked':
        return 'error';
      case 'done':
        return 'success';
      default:
        return 'default';
    }
  };

  const formatStatus = (status: string) => {
    return status.replace('_', ' ').replace(/\b\w/g, l => l.toUpperCase());
  };

  const getCategoryChipColor = (category: string): "default" | "primary" | "secondary" | "error" | "info" | "success" | "warning" => {
    switch (category) {
      case 'heating':
        return 'error';
      case 'improvement':
        return 'primary';
      case 'lighting':
        return 'warning';
      case 'outside_press':
        return 'info';
      case 'videography':
        return 'secondary';
      case 'audience_experience':
        return 'success';
      default:
        return 'default';
    }
  };

  if (state.error) return <Alert severity="error">{state.error}</Alert>;

  const { taskboards, userSubtasks, loading } = state;

  return (
    <Paper elevation={0} sx={{ border: 1, borderColor: 'divider' }}>
      <Box 
        sx={{
          p: 2,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          borderBottom: 1,
          borderColor: 'divider',
          cursor: 'pointer'
        }}
        onClick={() => setExpanded(!expanded)}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <Badge 
            badgeContent={taskboards.length + userSubtasks.length} 
            color="primary"
          >
            <ListTodo />
          </Badge>
          <Typography variant="subtitle1">Your Project Tasks</Typography>
        </Box>
        <Clock />
      </Box>

      {expanded && (
        <Box sx={{ p: 2 }}>
          {/* Main Tasks */}
          {taskboards.length > 0 && (
            <>
              <Typography variant="subtitle2" color="text.secondary" sx={{ mb: 2 }}>
                Main Tasks
              </Typography>
              <List sx={{ mb: 2 }}>
                {taskboards.map((task) => (
                  <ListItem
                    key={task._id}
                    sx={{
                      mb: 1,
                      bgcolor: 'background.paper',
                      borderRadius: 1,
                      border: 1,
                      borderColor: 'divider'
                    }}
                    onClick={() => navigate(`/admin/taskboard?task=${task._id}`)}
                  >
                    <ListItemIcon>
                      <Chip
                        label={task.category.replace('_', ' ')}
                        size="small"
                        color={getCategoryChipColor(task.category)}
                        sx={{ textTransform: 'capitalize' }}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={task.title}
                      secondary={
                        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mt: 0.5 }}>
                          <Chip
                            label={formatStatus(task.status)}
                            size="small"
                            color={getStatusColor(task.status)}
                          />
                          <Typography variant="caption" color="text.secondary">
                            Due: {format(new Date(task.dueDate), 'PP')}
                          </Typography>
                        </Box>
                      }
                    />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" size="small">
                        <ExternalLink size={16} />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </>
          )}

          {/* Subtasks */}
          {userSubtasks.length > 0 && (
            <>
              <Divider sx={{ my: 2 }} />
              <Typography variant="subtitle2" color="text.secondary" gutterBottom>
                Your Subtasks
              </Typography>
              <List>
                {userSubtasks.map((subtask) => (
                  <ListItem
                    key={subtask._id}
                    sx={{
                      mb: 1,
                      bgcolor: 'background.paper',
                      borderRadius: 1,
                      border: 1,
                      borderColor: 'divider'
                    }}
                    onClick={() => navigate(`/admin/taskboard?task=${subtask.boardId}`)}
                  >
                    <ListItemIcon>
                      <Checkbox
                        edge="start"
                        checked={subtask.completed}
                      />
                    </ListItemIcon>
                    <ListItemText
                      primary={subtask.title}
                      secondary={
                        <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                          <Typography variant="caption" color="text.secondary">
                            From: {subtask.boardTitle}
                          </Typography>
                          {subtask.dueDate && (
                            <Typography variant="caption">
                              Due: {format(new Date(subtask.dueDate), 'PP')}
                            </Typography>
                          )}
                        </Box>
                      }
                    />
                  </ListItem>
                ))}
              </List>
            </>
          )}

          {taskboards.length === 0 && userSubtasks.length === 0 && (
            <Typography color="text.secondary">
              No project tasks assigned to you
            </Typography>
          )}
        </Box>
      )}
    </Paper>
  );
};

export default TaskboardList;