import React, { useEffect, useState, useMemo } from 'react';
import {
  Checkbox,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Button,
  Typography,
  CircularProgress,
  Chip,
  Avatar,
  Box,
  Tooltip,
  FormControlLabel,
  MenuItem,
  Select
} from '@mui/material';
import { MdPerson } from 'react-icons/md';
import axiosInstance from '../../utils/axiosConfig';
import { Diversity3 } from '@mui/icons-material';

interface Staff {
  _id: string;
  name: string;
  roles: string[];
  profileImage: string;}

interface AssignedStaff {
  staffId: string;
  assignedRole?: string;
}

interface Performance {
  _id: string;
  staff: AssignedStaff[]; // Assigned staff with optional roles
}

interface StaffRosterProps {
  performanceId: string;
  onAssignmentComplete?: (updatedPerformance: any) => void;
}

const roleColors: Record<string, string> = {
  "Stage Manager": "#3B6FA0",  // Muted dark blue
  "Sound Engineer": "#5F4B8B", // Deep purple
  "Production Manager": "#B04A4A", // Darker red
  "Runner": "#357A5A", // Muted forest green
  "Front of House": "#A88532", // Darker mustard yellow
  "Staff": "#707B88", // Neutral dark gray
  "Stage Hand": "#2A5D91", // Deep steel blue
  "Vision Mix Engineer": "#9A563E", // Darker burnt orange
  "Volunteer": "#3E8071" // Muted teal
};

const StaffRoster: React.FC<StaffRosterProps> = ({ performanceId, onAssignmentComplete }) => {
  const [staff, setStaff] = useState<Staff[]>([]);
  const [selectedStaff, setSelectedStaff] = useState<AssignedStaff[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);



  const handleListItemClick = (staffId: string, event: React.MouseEvent) => {
    // Don't toggle selection if clicking the Select component
    if ((event.target as HTMLElement).closest('.MuiSelect-root')) {
      return;
    }
    handleToggle(staffId);
  };


  useEffect(() => {
    console.log("🔍 Staff Data:", staff);
  }, [staff]);

  useEffect(() => {
    fetchData();
}, [performanceId]);
  

  const fetchData = async () => {
    try {
      const [staffRes, perfRes] = await Promise.all([
        axiosInstance.get('/api/personnel'),
        axiosInstance.get(`/api/performances/${performanceId}`)
      ]);
  
      console.log('Fetched Performance:', perfRes.data);
      console.log('Pre-assigned Staff:', perfRes.data.staff);
  
      setStaff(staffRes.data);
  
      // Transform the existing staff assignments to match our expected format
      const existingAssignments = perfRes.data.staff?.map((staffMember: any) => ({
        staffId: typeof staffMember.staffId === 'object' ? staffMember.staffId._id : staffMember.staffId,
        assignedRole: staffMember.assignedRole || ""
      })) || [];
  
      console.log('Transformed existing assignments:', existingAssignments);
      setSelectedStaff(existingAssignments);
      setLoading(false);
    } catch (err) {
      console.error('Error fetching data:', err);
      setError('Failed to load data. Please try again.');
      setLoading(false);
    }
  };

  const handleToggle = (staffId: string) => {
    setSelectedStaff(prev => {
      const exists = prev.some(s => s.staffId === staffId);
      if (exists) {
        return prev.filter(s => s.staffId !== staffId); // Uncheck staff
      } else {
        return [...prev, { staffId, assignedRole: "" }]; // Ensure empty role, so UI updates correctly
      }
    });
  };

  const handleRoleChange = (staffId: string, assignedRole: string) => {
    setSelectedStaff(prev =>
      prev.map(s => 
        s.staffId === staffId ? { ...s, assignedRole } : s
      )
    );
  };

  const handleSelectAll = () => {
    setSelectedStaff(prev =>
      prev.length === staff.length ? [] : staff.map(s => ({ staffId: s._id, assignedRole: "" }))
    );
  };

  const handleAssignStaff = async () => {
    try {
      const response = await axiosInstance.put(
        `/api/performances/${performanceId}/assign-staff`,
        { staffAssignments: selectedStaff }
      );
  
      if (onAssignmentComplete) {
        onAssignmentComplete(response.data);
      }
      setSelectedStaff(response.data.staff);
    } catch (err) {
      console.error('Error assigning staff:', err);
      setError('Failed to assign staff members. Please try again.');
    }
  };

  const allSelected = useMemo(() => staff.length > 0 && selectedStaff.length === staff.length, [staff, selectedStaff]);

  if (loading) return <Box className="flex justify-center items-center p-4"><CircularProgress /></Box>;
  if (error) return <Typography color="error" className="p-4">{error}</Typography>;
  if (staff.length === 0) return <Typography className="p-4">No staff members available.</Typography>;


  return (
    <div className="p-4">
        <Typography variant="h4" gutterBottom color="white" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
  				<Diversity3 fontSize="small" />
          Assign Staff
        </Typography>

      {/* Select All Checkbox */}
      <FormControlLabel
        control={<Checkbox checked={allSelected} onChange={handleSelectAll} />}
        label="Select All"
        className="mb-2"
      />

<List className="mb-4">
        {staff.map((member) => {
   const selectedEntry = selectedStaff.find(s => s.staffId === member._id);
   const isSelected = !!selectedEntry;
   const assignedRole = selectedEntry?.assignedRole || "";
   
   console.log(`Staff member ${member.name}:`, { isSelected, assignedRole }); // Debug log


          return (
            <ListItem
              key={member._id}
              dense
              button
              onClick={(e) => handleListItemClick(member._id, e)}
              className={`transition-all p-3 rounded-md cursor-pointer ${
                isSelected ? 'bg-gray-200 border-l-4 border-green-500' : 'hover:bg-gray-50'
              }`}
            >
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={isSelected}
                  tabIndex={-1}
                  disableRipple
                  sx={{
                    transform: "scale(1.5)",  // Bigger checkbox
                    color: isSelected ? "#4CAF50" : undefined, // Green if selected
                  }}
                />
              </ListItemIcon>

              <Avatar
  
  key={member.profileImage} // Forces re-render when URL changes
  className="mr-3"
  src={member.profileImage && member.profileImage.trim() !== "" ? member.profileImage : undefined}
>
  {!member.profileImage || member.profileImage.trim() === "" ? <MdPerson /> : null}
</Avatar>
              <ListItemText
                primary={
                  <Typography
                    variant="body1"
                    fontWeight={isSelected ? "bold" : "normal"}
                    sx={{ color: isSelected ? "#eef520" : undefined }}
                  >
                    {member.name}
                  </Typography>
                }
                secondary={
                  <Box className="flex flex-wrap gap-2 mt-1">
                    {member.roles.map((role, index) => (
                      <Chip
                        key={index}
                        label={role}
                        size="small"
                        style={{ backgroundColor: roleColors[role] || "#ccc", color: "#fff" }}
                      />
                    ))}
                  </Box>
                }
              />

              {/* Assign Specific Job Dropdown */}
              <Select
                  value={assignedRole}
                  onChange={(e) => handleRoleChange(member._id, e.target.value)}
                  onClick={(e) => e.stopPropagation()} // Stop event bubbling
                  displayEmpty
                  size="small"
                  className="MuiSelect-root" // Add this class for identification
                  sx={{ minWidth: 160, ml: 2 }}
                >
                  <MenuItem value="">No Specific Role</MenuItem>
                  {member.roles.map((role, index) => (
                    <MenuItem 
                      key={index} 
                      value={role}
                      onClick={(e) => e.stopPropagation()} // Stop event bubbling
                    >
                      {role}
                    </MenuItem>
                  ))}
                </Select>
            </ListItem>
          );
        })}
      </List>

      {/* Assign Button */}
      <Tooltip title={selectedStaff.length === 0 ? "Select at least one staff member" : ""}>
        <span>
          <Button
            variant="contained"
            color="primary"
            onClick={handleAssignStaff}
            disabled={selectedStaff.length === 0}
            fullWidth
          >
            Assign Selected Staff
          </Button>
        </span>
      </Tooltip>
    </div>
  );
};

export default StaffRoster;