import React, { useState, useEffect, Suspense } from 'react';
import { Container, Typography, CircularProgress, Box, Skeleton, Stack, Grid } from '@mui/material';
import { MUSIC_QUOTES } from '../constants/musicQuotes';
import { getTitoEvents, getTitoRegistrations, getTitoReleases } from '../services/titoApi';
import { Event, Performance, Application, TitoEvent } from '../types/dashboard';
import axiosInstance from '../utils/axiosConfig';
import UserGreeting from '../components/UserGreeting';
import QuoteCard from '../components/dashboard/QuoteCard';
import UserTasksOverview from '../components/dashboard/UserTasksOverview';
import { useAuth } from '../contexts/AuthContext';  // Instead of useUser



// Lazy load larger components
const DashboardStatsCards = React.lazy(() => import('../components/dashboard/DashboardStatsCards'));
const DashboardTabs = React.lazy(() => import('../components/dashboard/DashboardTabs'));
const SalesAnalysisSection = React.lazy(() => import('../components/dashboard/SalesAnalysisSection'));

interface DashboardData {
  upcomingPerformances: Performance[];
  performances: Performance[];
  titoEvents: TitoEvent[];
  banBamApplications: Application[];
  navigatorApplications: Application[];
  totalBands: number;
  lastLogin: string | null;
  ticketsSoldSinceLastLogin: number;
}

// Initial loading component
const LoadingFallback = () => (
  <Box 
    sx={{ 
      width: '100%',
      minHeight: '100vh',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 2,
      pt: 4
    }}
  >
    <CircularProgress />
    <Typography variant="h6" color="text.secondary">
      Loading Dashboard...
    </Typography>
  </Box>
);

const Dashboard: React.FC = () => {
  const { user } = useAuth();  // Instead of useUser
  console.log('Dashboard - Current user:', {
    exists: !!user,
    id: user?._id,
    role: user?.role
  });
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [currentQuote] = useState(() => 
    MUSIC_QUOTES[Math.floor(Math.random() * MUSIC_QUOTES.length)]
  );
  
  const [dashboardData, setDashboardData] = useState<DashboardData>({
    upcomingPerformances: [],
    performances: [],
    titoEvents: [],
    banBamApplications: [],
    navigatorApplications: [],
    totalBands: 0,
    lastLogin: null,
    ticketsSoldSinceLastLogin: 0
  });
  
  const [tabValue, setTabValue] = useState(0);
  const [expanded, setExpanded] = useState<string | false>(false);

  // Primary data fetching
  useEffect(() => {
    const fetchInitialData = async () => {
      try {
        const [eventsResponse, performancesResponse, banBamResponse, navigatorResponse, bandsResponse] = 
          await Promise.all([
            axiosInstance.get<Event[]>('/api/events'),
            axiosInstance.get<Performance[]>('/api/performances'),
            axiosInstance.get<Application[]>('/api/banbam'),
            axiosInstance.get<Application[]>('/api/navigator'),
            axiosInstance.get<any[]>('/api/bandrecommendations')
          ]);

        setDashboardData(prev => ({
          ...prev,
          upcomingEvents: eventsResponse.data,
          performances: performancesResponse.data,
          banBamApplications: banBamResponse.data,
          navigatorApplications: navigatorResponse.data,
          totalBands: bandsResponse.data.length
        }));

        setLoading(false);
      } catch (err) {
        console.error('Error fetching initial dashboard data:', err);
        setError('Failed to fetch dashboard data');
        setLoading(false);
      }
    };

    fetchInitialData();
  }, []);

useEffect(() => {
  if (!loading) {
    const fetchSecondaryData = async () => {
      try {
        console.log('Fetching secondary data...');
        const [userResponse, titoResponse] = await Promise.all([
          axiosInstance.get<{ lastLogin: string }>('/api/users/me/lastLogin'),
          getTitoEvents()
        ]);

        console.log('Tito response:', titoResponse);

        if (titoResponse.data?.events) {
          console.log('Processing Tito events:', titoResponse.data.events);
          const processedEvents = await processTicketData(titoResponse.data.events);
          console.log('Processed events:', processedEvents);
          
          setDashboardData(prev => {
            console.log('Updating dashboard data with events:', processedEvents);
            return {
              ...prev,
              titoEvents: processedEvents,
              lastLogin: userResponse.data.lastLogin
            };
          });
        } else {
          console.warn('No events found in Tito response');
        }
      } catch (err) {
        console.error('Error fetching secondary data:', err);
      }
    };

    fetchSecondaryData();
  }
}, [loading]);

// Helper function to process Tito events data
const processTicketData = async (events: any[]): Promise<TitoEvent[]> => {
console.log('Starting to process ticket data for events:', events);

try {
  const processedEvents = await Promise.all(
    events
      .filter(event => {
        console.log('Checking event:', event.title, 'live status:', event.live);
        return event.live;
      })
      .map(async (event) => {
        console.log('Fetching details for event:', event.title);
        try {
          const [registrationsResponse, releasesResponse] = await Promise.all([
            getTitoRegistrations(event.slug),
            getTitoReleases(event.slug)
          ]);

          console.log('Registrations for', event.title, ':', registrationsResponse.data);
          console.log('Releases for', event.title, ':', releasesResponse.data);

          const totalSold = registrationsResponse.data.registrations.reduce(
            (sum: number, reg: any) => sum + reg.tickets_count,
            0
          );

          const totalAvailable = releasesResponse.data.releases.reduce(
            (sum: number, release: any) => sum + release.quantity,
            0
          );

          return {
            title: event.title,
            totalSold,
            totalAvailable,
            start_date: event.start_date,
            releases: releasesResponse.data.releases,
            registrations: registrationsResponse.data.registrations,
            last_ticket_sold_at: registrationsResponse.data.registrations[0]?.completed_at
          };
        } catch (error) {
          console.error('Error processing event:', event.title, error);
          return null;
        }
      })
  );

  console.log('Processed events before filtering:', processedEvents);
  const filteredEvents = processedEvents
    .filter((event): event is TitoEvent => 
      event !== null && new Date(event.start_date) > new Date()
    );
  console.log('Final filtered events:', filteredEvents);
  return filteredEvents;
} catch (error) {
  console.error('Error in processTicketData:', error);
  return [];
}
};

  const pendingPerformances = dashboardData.performances.filter(p => p.status === 'pending');
  const confirmedPerformances = dashboardData.performances.filter(p => p.status === 'confirmed');

  // Main render

  console.log('Dashboard - About to render UserTasksOverview:', { 
    userExists: !!user,
    userId: user?._id, // This should now show the actual ID
    hasId: !!user?._id
  });


    
  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Typography variant="h3" component="h1" gutterBottom>
        Your Dashboard
      </Typography>

      <Grid container spacing={3} alignItems="stretch">
        <Grid item xs={12} md={6}>
          <Box sx={{ height: '100%' }}>
            <UserGreeting ticketsSold={dashboardData.ticketsSoldSinceLastLogin} />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ height: '100%' }}>
            <QuoteCard quote={currentQuote} />
          </Box>
        </Grid>
      </Grid>
      <Suspense fallback={
        <Stack spacing={3} sx={{ mt: 4 }}>
          <Skeleton variant="rectangular" height={120} />
          <Skeleton variant="rectangular" height={200} />
          <Skeleton variant="rectangular" height={400} />
        </Stack>
      }>

{user?._id && (
  <Box sx={{ mt: 4, mb: 4 }}>
    <UserTasksOverview userId={user._id} />
  </Box>
)}

    {/* Your existing DashboardStatsCards */}
    <DashboardStatsCards 
      stats={{
        upcomingPerformances: dashboardData.performances.length,
        totalBands: dashboardData.totalBands,
        pendingPerformances: pendingPerformances.length,
        confirmedPerformances: confirmedPerformances.length,
        banBamApplications: dashboardData.banBamApplications.length,
        navigatorApplications: dashboardData.navigatorApplications.length
      }}
      onTabChange={setTabValue}
    />


        <Box sx={{ mt: 4 }}>
          <SalesAnalysisSection 
            events={dashboardData.titoEvents}
            expanded={expanded}
            onAccordionChange={(panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
              setExpanded(isExpanded ? panel : false);
            }}
          />
        </Box>

        <Box sx={{ mt: 4 }}>
          <DashboardTabs 
            pendingPerformances={pendingPerformances}
            confirmedPerformances={confirmedPerformances}
            tabValue={tabValue}
            onTabChange={(event: React.SyntheticEvent, newValue: number) => setTabValue(newValue)}
          />
        </Box>
      </Suspense>
    </Container>
  );
};

// Helper function to process Tito events data
const processTicketData = async (events: any[]): Promise<TitoEvent[]> => {
  const processedEvents = await Promise.all(
    events
      .filter(event => event.live)
      .map(async (event) => {
        const [registrationsResponse, releasesResponse] = await Promise.all([
          getTitoRegistrations(event.slug),
          getTitoReleases(event.slug)
        ]);

        return {
          title: event.title,
          totalSold: registrationsResponse.data.registrations.reduce(
            (sum: number, reg: any) => sum + reg.tickets_count,
            0
          ),
          totalAvailable: releasesResponse.data.releases.reduce(
            (sum: number, release: any) => sum + release.quantity,
            0
          ),
          start_date: event.start_date,
          releases: releasesResponse.data.releases,
          registrations: registrationsResponse.data.registrations,
          last_ticket_sold_at: registrationsResponse.data.registrations[0]?.completed_at
        };
      })
  );

  return processedEvents.filter(event => new Date(event.start_date) > new Date());
};

export default Dashboard;