import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  Typography,
  CircularProgress,
  Box,
  Card,
  CardContent,
  Grid,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Alert,
  TextField,
  Checkbox,
  FormControlLabel,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material';
import { PieChart, Pie, Cell, Tooltip, ResponsiveContainer, Legend } from 'recharts';
import { Edit, Save, Plus, Delete } from 'lucide-react';
import axiosInstance from '../utils/axiosConfig';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#A020F0', '#E91E63'];

const EditableTicketConfigDetail: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [config, setConfig] = useState<any>(null);
  const [performance, setPerformance] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [isEditing, setIsEditing] = useState(false);
  const [editedTickets, setEditedTickets] = useState<any[]>([]);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [newTicket, setNewTicket] = useState({
    name: '',
    quantity: 0,
    price: 0,
    vatRate: 0.135,
    vatExempt: false,
    revenueImpact: true,
  });

  useEffect(() => {
    fetchData();
  }, [id]);

  const fetchData = async () => {
    try {
      setLoading(true);
      const configRes = await axiosInstance.get(`/api/ticket-configs/${id}`);
      setConfig(configRes.data);
      setEditedTickets(configRes.data.ticketTypes);

      if (configRes.data.performanceId) {
        const perfId = typeof configRes.data.performanceId === 'string' 
          ? configRes.data.performanceId 
          : configRes.data.performanceId._id;
        const performanceRes = await axiosInstance.get(`/api/performances/${perfId}`);
        setPerformance(performanceRes.data);
      }
    } catch (err: any) {
      setError(err.message || 'Error fetching configuration details');
    } finally {
      setLoading(false);
    }
  };

  const handleSave = async () => {
    try {
      await axiosInstance.put(`/api/ticket-configs/${id}`, {
        ...config,
        ticketTypes: editedTickets,
      });
      setIsEditing(false);
      fetchData(); // Refresh data
    } catch (err: any) {
      setError('Failed to save changes');
    }
  };

  const handleTicketChange = (index: number, field: string, value: any) => {
    const updatedTickets = [...editedTickets];
    updatedTickets[index] = {
      ...updatedTickets[index],
      [field]: field === 'price' || field === 'quantity' || field === 'vatRate' 
        ? parseFloat(value) 
        : value
    };
    setEditedTickets(updatedTickets);
  };

  const handleAddTicket = () => {
    setEditedTickets([...editedTickets, newTicket]);
    setShowAddDialog(false);
    setNewTicket({
      name: '',
      quantity: 0,
      price: 0,
      vatRate: 0.135,
      vatExempt: false,
      revenueImpact: true,
    });
  };

  const handleDeleteTicket = (index: number) => {
    const updatedTickets = editedTickets.filter((_, i) => i !== index);
    setEditedTickets(updatedTickets);
  };

  if (loading) return <CircularProgress sx={{ display: 'block', mx: 'auto', mt: 4 }} />;
  if (error) return <Alert severity="error">{error}</Alert>;
  if (!config) return <Typography variant="h6">No configuration found.</Typography>;

  const calculateFinancials = () => {
    let totalRevenue = 0;
    let totalRevenueWithVAT = 0;
    let vatAmount = 0;

    editedTickets.forEach(tt => {
      const revenue = tt.quantity * tt.price;
      const vat = tt.vatExempt ? 0 : revenue * tt.vatRate;
      const revenueWithVAT = revenue - vat;

      totalRevenue += revenue;
      totalRevenueWithVAT += revenueWithVAT;
      vatAmount += vat;
    });

    return { totalRevenue, totalRevenueWithVAT, vatAmount };
  };

  const { totalRevenue, totalRevenueWithVAT, vatAmount } = calculateFinancials();

  const ticketData = editedTickets.map(tt => ({
    name: tt.name,
    value: tt.quantity * tt.price,
  }));

  return (
    <Box sx={{ p: 3 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h4">
          Ticket Configuration
        </Typography>
        <Box>
          {isEditing ? (
            <>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleSave}
                startIcon={<Save />}
                sx={{ mr: 1 }}
              >
                Save Changes
              </Button>
              <Button 
                variant="outlined"
                onClick={() => {
                  setIsEditing(false);
                  setEditedTickets(config.ticketTypes);
                }}
              >
                Cancel
              </Button>
            </>
          ) : (
            <Button 
              variant="contained" 
              onClick={() => setIsEditing(true)}
              startIcon={<Edit />}
            >
              Edit Configuration
            </Button>
          )}
        </Box>
      </Box>

      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Typography variant="h6" gutterBottom>Revenue Summary</Typography>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6}>
              <Typography><strong>Venue:</strong> {config.venueId.name}</Typography>
              <Typography><strong>Capacity:</strong> {config.venueId.capacity}</Typography>
            </Grid>
            <Grid item xs={12} sm={6}>
              <Typography><strong>Total Revenue (Incl. VAT):</strong> €{totalRevenue.toFixed(2)}</Typography>
              <Typography><strong>Total VAT:</strong> €{vatAmount.toFixed(2)}</Typography>
              <Typography variant="h6" sx={{ color: 'success.main', mt: 1 }}>
                <strong>Net Revenue:</strong> €{totalRevenueWithVAT.toFixed(2)}
              </Typography>
            </Grid>
          </Grid>
        </CardContent>
      </Card>

      <Card sx={{ mb: 3 }}>
        <CardContent>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Typography variant="h6">Ticket Types</Typography>
            {isEditing && (
              <Button
                startIcon={<Plus />}
                variant="contained"
                onClick={() => setShowAddDialog(true)}
              >
                Add Ticket Type
              </Button>
            )}
          </Box>
          
          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><strong>Name</strong></TableCell>
                  <TableCell><strong>Quantity</strong></TableCell>
                  <TableCell><strong>Price (€)</strong></TableCell>
                  <TableCell><strong>Revenue (€)</strong></TableCell>
                  <TableCell><strong>VAT Rate</strong></TableCell>
                  <TableCell><strong>Options</strong></TableCell>
                  {isEditing && <TableCell><strong>Actions</strong></TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {editedTickets.map((tt, i) => (
                  <TableRow key={i}>
                    <TableCell>
                      {isEditing ? (
                        <TextField
                          value={tt.name}
                          onChange={(e) => handleTicketChange(i, 'name', e.target.value)}
                          size="small"
                        />
                      ) : tt.name}
                    </TableCell>
                    <TableCell>
                      {isEditing ? (
                        <TextField
                          type="number"
                          value={tt.quantity}
                          onChange={(e) => handleTicketChange(i, 'quantity', e.target.value)}
                          size="small"
                        />
                      ) : tt.quantity}
                    </TableCell>
                    <TableCell>
                      {isEditing ? (
                        <TextField
                          type="number"
                          value={tt.price}
                          onChange={(e) => handleTicketChange(i, 'price', e.target.value)}
                          size="small"
                        />
                      ) : `€${tt.price.toFixed(2)}`}
                    </TableCell>
                    <TableCell>€{(tt.quantity * tt.price).toFixed(2)}</TableCell>
                    <TableCell>
                      {isEditing ? (
                        <TextField
                          type="number"
                          value={tt.vatRate}
                          onChange={(e) => handleTicketChange(i, 'vatRate', e.target.value)}
                          size="small"
                          inputProps={{ step: 0.01 }}
                        />
                      ) : `${(tt.vatRate * 100).toFixed(1)}%`}
                    </TableCell>
                    <TableCell>
                      {isEditing ? (
                        <Box>
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={tt.vatExempt}
                                onChange={(e) => handleTicketChange(i, 'vatExempt', e.target.checked)}
                                size="small"
                              />
                            }
                            label="VAT Exempt"
                          />
                          <FormControlLabel
                            control={
                              <Checkbox
                                checked={tt.revenueImpact}
                                onChange={(e) => handleTicketChange(i, 'revenueImpact', e.target.checked)}
                                size="small"
                              />
                            }
                            label="Revenue Impact"
                          />
                        </Box>
                      ) : (
                        <Box>
                          {tt.vatExempt && <Typography>VAT Exempt</Typography>}
                          {tt.revenueImpact && <Typography>Revenue Impact</Typography>}
                        </Box>
                      )}
                    </TableCell>
                    {isEditing && (
                      <TableCell>
                        <IconButton 
                          onClick={() => handleDeleteTicket(i)}
                          color="error"
                          size="small"
                        >
                          <Delete />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </CardContent>
      </Card>

      <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>Revenue Distribution</Typography>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie 
                data={ticketData} 
                dataKey="value" 
                nameKey="name" 
                cx="50%" 
                cy="50%" 
                outerRadius={80}
              >
                {ticketData.map((_, index) => (
                  <Cell key={index} fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </CardContent>
      </Card>

      <Dialog open={showAddDialog} onClose={() => setShowAddDialog(false)}>
        <DialogTitle>Add New Ticket Type</DialogTitle>
        <DialogContent>
          <Box sx={{ pt: 2, display: 'flex', flexDirection: 'column', gap: 2 }}>
            <TextField
              label="Name"
              value={newTicket.name}
              onChange={(e) => setNewTicket({ ...newTicket, name: e.target.value })}
              fullWidth
            />
            <TextField
              label="Quantity"
              type="number"
              value={newTicket.quantity}
              onChange={(e) => setNewTicket({ ...newTicket, quantity: parseInt(e.target.value) })}
              fullWidth
            />
            <TextField
              label="Price"
              type="number"
              value={newTicket.price}
              onChange={(e) => setNewTicket({ ...newTicket, price: parseFloat(e.target.value) })}
              fullWidth
            />
            <TextField
              label="VAT Rate"
              type="number"
              value={newTicket.vatRate}
              onChange={(e) => setNewTicket({ ...newTicket, vatRate: parseFloat(e.target.value) })}
              fullWidth
              inputProps={{ step: 0.01 }}
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={newTicket.vatExempt}
                  onChange={(e) => setNewTicket({ ...newTicket, vatExempt: e.target.checked })}
                />
              }
              label="VAT Exempt"
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={newTicket.revenueImpact}
                  onChange={(e) => setNewTicket({ ...newTicket, revenueImpact: e.target.checked })}
                />
              }
              label="Revenue Impact"
            />
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowAddDialog(false)}>Cancel</Button>
          <Button onClick={handleAddTicket} variant="contained" color="primary">
            Add Ticket Type
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default EditableTicketConfigDetail;