import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Button, Box } from '@mui/material';
import { 
  Email, LocationOn, Person, CalendarToday,
  MusicNote, Article, ReceiptLong, ConfirmationNumberRounded,
  ModeEdit,
  Update,
  Adjust,
  Groups2,
  ArrowForward
} from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Performance, BandRecommendation, Venue } from '../../types/mongodb';
import axiosInstance from '../../utils/axiosConfig';
import ContactModal from './ContactModal';
import { Strand } from '../../types/Strand';

interface ActionSectionProps {
  onManageStaffClick: () => void;  // Add this line
  performance: Performance & {
    band?: string | BandRecommendation;
    venue?: string | Venue;
  };
}

interface BandInfo {
  name: string; 
  primaryContact: string;
  email: string;
  phone: string;
}

// Add these interfaces within the existing interfaces:
interface ContactInfo {
  name: string;
  email?: string;
  phone?: string;
  type: 'artist' | 'venue';
}


const ActionSection: React.FC<ActionSectionProps> = ({ performance, onManageStaffClick}) => {
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [currentContact, setCurrentContact] = useState<ContactInfo | null>(null);
  const [artistContact, setArtistContact] = useState<ContactInfo | null>(null);
  const [venueContact, setVenueContact] = useState<ContactInfo | null>(null);

  const fetchArtistContact = async () => {
    try {
      if (hasBandRecord) {
        const band = performance.band as BandRecommendation;
        setArtistContact({
          name: band.artistName,
          email: band.contactEmail,
          phone: band.contactPhone,
          type: 'artist'
        });
      } else if (performance.simpleBandName) {
        const response = await axiosInstance.get('/api/programmings/search', {
          params: { artistName: performance.simpleBandName }
        });
        
        if (response.data && response.data.length > 0) {
          const latestProgramming = response.data[0];
          setArtistContact({
            name: latestProgramming.artistName,
            email: latestProgramming.email,
            phone: latestProgramming.phone,
            type: 'artist'
          });
        }
      }
    } catch (error) {
      console.error('Error fetching artist contact:', error);
    }
  };
  
  const fetchVenueContact = async () => {
    try {
      if (hasVenueRecord) {
        const venue = performance.venue as Venue;
        setVenueContact({
          name: venue.name,
          email: venue.contactInfo?.email,
          phone: venue.contactInfo?.phone,
          type: 'venue'
        });
      } else if (typeof performance.venue === 'string') {
        const response = await axiosInstance.get(`/api/venues/${performance.venue}`);
        if (response.data) {
          setVenueContact({
            name: response.data.name,
            email: response.data.contactInfo?.email,
            phone: response.data.contactInfo?.phone,
            type: 'venue'
          });
        }
      }
    } catch (error) {
      console.error('Error fetching venue contact:', error);
    }
  };
  
  // Add useEffect hook:
  useEffect(() => {
    fetchArtistContact();
    fetchVenueContact();
  }, [performance]);
  

  const hasBandRecord = performance.band && 
    typeof performance.band === 'object' && 
    '_id' in performance.band;

  const hasVenueRecord = performance.venue &&
    typeof performance.venue === 'object' &&
    '_id' in performance.venue;

  const getBandInfo = async (): Promise<BandInfo> => {
    // First try BandRecommendation
    if (hasBandRecord) {
      const band = performance.band as BandRecommendation;
      return {
        name: band.artistName,
        primaryContact: band.contactName || '',
        email: band.contactEmail || '',
        phone: band.contactPhone || ''
      };
    }

    // If no BandRecommendation, try Programming database
    const bandName = performance.simpleBandName || '';
    try {
      const response = await axiosInstance.get('/api/programmings/search', {
        params: { artistName: bandName }
      });
      
      if (response.data && response.data.length > 0) {
        const latestProgramming = response.data[0];
        return {
          name: latestProgramming.artistName,
          primaryContact: latestProgramming.primaryContact || '',
          email: latestProgramming.email || '',
          phone: latestProgramming.phone || ''
        };
      }
    } catch (error) {
      console.error('Error fetching programming data:', error);
    }
  

    // Fallback to Performance data
    return {
      name: performance.simpleBandName || '',
      primaryContact: '',  // No primary contact in basic performance data
      email: '',
      phone: ''
    };
  };
  
  const handleTicketReview = async () => {
    try {
      setIsLoading(true);
      // Search for ticket configuration using the performance ID
      const response = await axiosInstance.get('/api/ticket-configs/search', {
        params: {
          performanceId: performance._id,
          selectionMethod: 'event'
        }
      });
      
      if (response.data && response.data._id) {
        // If configuration exists, navigate to its page
        navigate(`/tix-list/${response.data._id}`);
      } else {
        // If no configuration exists, navigate to the main ticketing page
        navigate('/tix-list');
      }
    } catch (error) {
      console.error('Error checking ticket configuration:', error);
      // On error, default to main ticketing page
      navigate('/tix-list');
    } finally {
      setIsLoading(false);
    }
  };

  const handleCreateContract = async () => {
    try {
        setIsLoading(true);
      
        // Safely access nested properties
        const costs = performance.costs || {};
        const travel = costs.travel || {};
        const accommodation = costs.accommodation || {};
        const perDiem = costs.perDiem || {};

        // Get band info from available sources
        const bandInfo = await getBandInfo();

        // Extract the strand ID correctly
        let strandName = '';
        const strandId = (performance.strandId as Strand)?._id || performance.strandId;  // Extract the _id if it's an object

        console.log("🔍 Checking extracted strandId:", strandId);
        
        if (strandId && typeof strandId === 'string') { 
            try {
                console.log("🚀 Fetching strand data...");
                const strandResponse = await axiosInstance.get(`/api/strands/${strandId}`);
                strandName = strandResponse.data?.name || '';
                console.log("✅ Strand name:", strandName);
            } catch (error) {
                console.error("❌ Error fetching strand data:", error);
            }
        } else {
            console.error("⛔ Invalid strandId:", strandId);
        }

        // Prepare contract data
        const contractData = {
            Item: bandInfo.name,
            Contact: bandInfo.primaryContact,
            Email: bandInfo.email,
            Phone: bandInfo.phone,
            'Event Date': performance.startTime || null,
            Venue: hasVenueRecord ? (performance.venue as Venue).name : '',
            Address: hasVenueRecord ? (performance.venue as Venue).location : '',
            'Soundcheck Time': performance.startTime || null,
            'Performance Type': 'Full Concert',
            'Min Duration': '90 minutes',
            Fees: costs.bandFee?.toString() || '0',
            'Int\'l Flights': travel.flights > 0 ? 'Required' : 'N/A',
            'Ground Transport': travel.groundTransport > 0 ? 'Required' : 'N/A',
            'Accommodation Type': accommodation.singleRooms > 0 
                ? 'Single Room' 
                : accommodation.twinRooms > 0 
                    ? 'Twin Room' 
                    : 'Not Required',
            'Accommodation Qty': ((accommodation.singleRooms || 0) + (accommodation.twinRooms || 0)).toString(),
            'PD Amount': perDiem.dailyRate?.toString() || '0',
            'PD Days': perDiem.numberOfDays?.toString() || '1',
            'PD Quantity': hasBandRecord 
                ? ((performance.band as BandRecommendation).totalMembers || 1).toString() 
                : '1',
            performanceId: performance._id,
            'Stage Time': performance.startTime || null,
            Doors: performance.startTime || null,
            'Soundcheck Duration': '60 minutes',
            'Payment Type': 'bank-transfer',
            'Activities Master': strandName, // ✅ Now correctly set
            'IMC Code': ''
        };

        console.log("✅ Contract Data Prepared:", contractData);

        console.log("✅ Contract Data Prepared:", contractData);
        console.log("🚀 Navigating to Contract Page with Data...");

        setTimeout(() => {
          navigate('/admin/contracts/new', { state: contractData });
        }, 500);

} catch (error) {
        console.error("Error creating contract:", error);
    } finally {
        setIsLoading(false);
    }
};

const handleCreateSchedule = async () => {
  try {
    setIsLoading(true);
    
    // Get venue info
    const venueData = hasVenueRecord ? performance.venue as Venue : null;
    if (!venueData) {
      throw new Error('Venue information is required to create a schedule');
    }
    
    // Get band info
    const bandInfo = await getBandInfo();
    
    console.log('Creating schedule with venue data:', venueData);
    console.log('Band info:', bandInfo);

    // Prepare schedule data
    const scheduleData = {
      performanceId: performance._id,
      performanceData: {
        bandName: bandInfo.name,
        contactName: bandInfo.primaryContact,
        contactEmail: bandInfo.email,
        contactPhone: bandInfo.phone,
        venue: {
          name: venueData.name,
          location: venueData.location,
        },
        timing: {
          date: performance.startTime ? new Date(performance.startTime) : null,
          startTime: performance.startTime ? new Date(performance.startTime) : null,
          endTime: performance.endTime ? new Date(performance.endTime) : null,
          soundcheckTime: performance.startTime ? new Date(performance.startTime) : null,
        },
        costs: {
          perDiem: {
            amount: performance.costs?.perDiem?.dailyRate || 0,
            days: performance.costs?.perDiem?.numberOfDays || 1,
            totalMembers: hasBandRecord ? (performance.band as BandRecommendation).totalMembers || 1 : 1
          },
          accommodation: {
            singleRooms: performance.costs?.accommodation?.singleRooms || 0,
            twinRooms: performance.costs?.accommodation?.twinRooms || 0
          },
          travel: {
            flights: performance.costs?.travel?.flights > 0,
            groundTransport: performance.costs?.travel?.groundTransport > 0
          }
        },
        staff: performance.staff || []
      }
    };

    // Navigate to schedule page with data
    navigate('/admin/performances/schedule', { 
      state: { scheduleData }
    });

  } catch (error) {
    console.error('Error preparing schedule:', error);
  } finally {
    setIsLoading(false);
  }
};

const ActionButton = ({ 
  icon, 
  children, 
  color = "primary", 
  onClick, 
  disabled,
  component,
  to,
  variant = "contained"
}: {
  icon: React.ReactNode;
  children: React.ReactNode;
  color?: "primary" | "secondary" | "success" | "error" | "info" | "warning";
  onClick?: () => void;
  disabled?: boolean;
  component?: any;
  to?: string;
  variant?: "contained" | "outlined";
}) => (
  <Button
    variant={variant}
    color={color}
    disabled={disabled}
    onClick={onClick}
    component={component}
    to={to}
    fullWidth
    sx={{
      display: 'flex',
      height: '40px',
      padding: 0,
      borderRadius: '26px',
      overflow: 'hidden',
      '& .MuiButton-startIcon': {
        margin: 0,
      }
    }}
  >
    <Box sx={{
      width: '52px',
      height: '52px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: '1px solid rgba(255,255,255,0.2)',
      bgcolor: 'rgba(0,0,0,0.1)',
    }}>
      {icon}
    </Box>
    <Box sx={{
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      px: 2,
      fontSize: '0.95rem',
      fontWeight: 500,
    }}>
      {children}
    </Box>
  </Button>
);

return (
  <Card sx={{ mb: 4 }}>
    <CardContent>
      <Typography variant="h5" gutterBottom sx={{ 
        display: 'flex', 
        alignItems: 'center' 
      }}>
        <Article sx={{ mr: 1 }} />
        Actions
      </Typography>
      
      <Box sx={{ 
        display: 'flex', 
        flexDirection: 'column',
        gap: 1.5
      }}>
        <ActionButton
          icon={<ModeEdit sx={{ fontSize: 28 }} />}
          color="info"
          onClick={() => navigate(`/admin/performances/edit/${performance._id}`)}
        >
          Edit Performance Details
        </ActionButton>

        <ActionButton
          icon={<Groups2 sx={{ fontSize: 28 }} />}
          color="success"
          onClick={onManageStaffClick}
        >
          Manage Staff
        </ActionButton>

        <ActionButton
          icon={<Email sx={{ fontSize: 28 }} />}
          color="primary"
          onClick={() => {
            setCurrentContact(artistContact);
            setContactModalOpen(true);
          }}
          disabled={!artistContact}
        >
          Contact Artist
        </ActionButton>

        <ActionButton
          icon={<LocationOn sx={{ fontSize: 28 }} />}
          color="secondary"
          onClick={() => {
            setCurrentContact(venueContact);
            setContactModalOpen(true);
          }}
          disabled={!venueContact}
        >
          Contact Venue
        </ActionButton>

        {hasBandRecord && (
          <ActionButton
            icon={<MusicNote sx={{ fontSize: 28 }} />}
            variant="outlined"
            component={Link}
            to={`/band/${(performance.band as BandRecommendation)._id}`}
          >
            View Band Profile
          </ActionButton>
        )}

        <ActionButton
          icon={<ConfirmationNumberRounded sx={{ fontSize: 28 }} />}
          onClick={handleTicketReview}
          disabled={isLoading}
        >
          {isLoading ? 'Loading...' : 'Review Ticketing'}
        </ActionButton>

        <ActionButton
          icon={<Update sx={{ fontSize: 28 }} />}
          color="success"
          onClick={handleCreateSchedule}
          disabled={isLoading}
        >
          {isLoading ? 'Preparing Schedule...' : 'Create Schedule'}
        </ActionButton>

        <ActionButton
          icon={<ReceiptLong sx={{ fontSize: 28 }} />}
          color="info"
          onClick={handleCreateContract}
          disabled={isLoading}
        >
          {isLoading ? 'Preparing Contract...' : 'Create Contract'}
        </ActionButton>

        <ActionButton
          icon={<Adjust sx={{ fontSize: 28 }} />}
          color="warning"
        >
          Actuals
        </ActionButton>

        <ActionButton
          icon={<ArrowForward sx={{ fontSize: 28 }} />}
          color="primary"
        >
          Post-Production
        </ActionButton>
      </Box>
        
        {currentContact && (
          <ContactModal
            open={contactModalOpen}
            onClose={() => setContactModalOpen(false)}
            contactData={currentContact}
          />
        )}
        
      </CardContent>
      
    </Card>

  );
};

export default ActionSection;