import React from 'react';
import { useNavigate } from 'react-router-dom';

interface ShowrunnrLogoProps {
  width?: number;
  height?: number;
  clickable?: boolean;
}

const ShowrunnrLogo: React.FC<ShowrunnrLogoProps> = ({ 
  width = 150,
  height = 40,
  clickable = true 
}) => {
  const navigate = useNavigate();
  const bars = [
    { baseHeight: 24, animDelay: 0 },
    { baseHeight: 16, animDelay: 0.1 },
    { baseHeight: 20, animDelay: 0.2 },
    { baseHeight: 12, animDelay: 0.3 }
  ];

  const handleClick = () => {
    if (clickable) {
      navigate('/admin/dashboard');
    }
  };

  return (
    <div 
      className={`inline-flex items-center transition-transform duration-300 ease-in-out ${
        clickable ? 'cursor-pointer hover:scale-105 hover:brightness-125' : ''
      }`}
      onClick={handleClick}
      role={clickable ? 'button' : 'presentation'}
      aria-label={clickable ? 'Go to dashboard' : 'Showrunnr logo'}
      title={clickable ? 'Go to dashboard' : ''}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 200 50"
        width={width}
        height={height}
        className="cursor-pointer"
      >
        <defs>
          <linearGradient id="note-gradient" x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor="#4FA8E8" />
            <stop offset="100%" stopColor="#50E3C2" />
          </linearGradient>
          <linearGradient id="bar-gradient" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor="#4FA8E8" />
            <stop offset="100%" stopColor="#50E3C2" />
          </linearGradient>
        </defs>

        {/* Proper eighth note */}
        <g transform="translate(5, 8) scale(0.7)">
          <ellipse 
            cx="12" 
            cy="30" 
            rx="6" 
            ry="4" 
            transform="rotate(-15 12 30)"
            fill="url(#note-gradient)"
          />
          <path
            d="M17 30 L17 10"
            stroke="url(#note-gradient)"
            strokeWidth="2"
            fill="none"
          />
          <path
            d="M17 10 C22 12, 22 16, 17 18"
            stroke="url(#note-gradient)"
            strokeWidth="2"
            fill="none"
          />
        </g>

        {/* Animated equalizer bars */}
        <g transform="translate(30, 10)">
          {bars.map((bar, i) => (
            <rect
              key={i}
              x={i * 6}
              y={25 - bar.baseHeight}
              width="4"
              height={bar.baseHeight}
              fill="url(#bar-gradient)"
              opacity="0.9"
            >
              <animate
                attributeName="height"
                values={`${bar.baseHeight};${bar.baseHeight * 1.5};${bar.baseHeight * 0.7};${bar.baseHeight}`}
                dur="1.2s"
                begin={`${bar.animDelay}s`}
                repeatCount="indefinite"
              />
              <animate
                attributeName="y"
                values={`${25 - bar.baseHeight};${25 - bar.baseHeight * 1.5};${25 - bar.baseHeight * 0.7};${25 - bar.baseHeight}`}
                dur="1.2s"
                begin={`${bar.animDelay}s`}
                repeatCount="indefinite"
              />
            </rect>
          ))}
        </g>

        {/* Text */}
        <text
          x="65"
          y="28"
          fontFamily="system-ui, -apple-system, sans-serif"
          fontSize="24"
          fontWeight="600"
          letterSpacing="-0.5"
        >
          <tspan fill="#D8D8D8">show</tspan>
          <tspan fill="#50E3C2">.runnr</tspan>
        </text>
      </svg>
    </div>
  );
};

export default ShowrunnrLogo;