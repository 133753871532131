import React, { useState, useEffect, useMemo } from 'react';
import axiosInstance from '../utils/axiosConfig';
import { useQuery } from '@tanstack/react-query';
import { 
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Paper,
  Box,
  Typography,
  Alert,
  Checkbox,
  Chip,
  IconButton,
  Stack,
  Popper,
} from '@mui/material';
import { 
  Add as AddIcon,
  Delete as DeleteIcon,
  Warning as UrgentIcon,
  People as PeopleIcon,
  BuildCircle as BuildIcon,
  CalendarToday as CalendarIcon,
  Email as EmailIcon,
  Phone as PhoneIcon,
  Person as PersonIcon,
  DeleteSweep as DeleteSweepIcon
} from '@mui/icons-material';

interface User {
    _id: string;
    name: string;
    email: string;
  }  

  interface Personnel {
    _id: string;
    name: string;
    email: string;
  }

interface Strand {
  _id: string;
  name: string;
  events: string[];
}

interface Event {
  _id: string;
  name: string;
  strandId: string;
  performances: string[];
}

interface Performance {
  _id: string;
  name: string;
  eventId: string;
}

interface Task {
  _id: string;
  text: string;
  completed: boolean;
  urgent: boolean;
  created: Date;
  createdBy?: string;
  assignedTo?: string;
  strandId?: string;
  eventId?: string;
  performanceId?: string;
}

interface Suggestion {
  type: 'mention' | 'tag';
  text: string;
  inputText: string;
  icon: React.ElementType;
}



const EnhancedTaskInput: React.FC<{
    value: string;
    onChange: (value: string) => void;
    onSubmit: () => void;
    personnel: Personnel[];
    users: User[];
  }> = ({ value, onChange, onSubmit, personnel, users }) => {
    const [inputValue, setInputValue] = useState(value);
    const [suggestions, setSuggestions] = useState<Suggestion[]>([]);
    const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
    
  
    const TAGS = [
      { text: '#urgent', inputText: '#u', icon: UrgentIcon },
      { text: '#priority', inputText: '#p', icon: UrgentIcon },
      { text: '#caitriona', inputText: '#c', icon: PeopleIcon },
      { text: '#aoife', inputText: '#a', icon: PeopleIcon },
      { text: '#bianca', inputText: '#b', icon: PeopleIcon },
      { text: '#louis', inputText: '#l', icon: PeopleIcon },
      { text: '#cooler', inputText: '#co', icon: BuildIcon },
      { text: '#email', inputText: '#e', icon: EmailIcon },
      { text: '#phone', inputText: '#ph', icon: PhoneIcon },
      { text: '#programming', inputText: '#pr', icon: BuildIcon },
    ];
  
    useEffect(() => {
      setInputValue(value);
    }, [value]);
  
    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setInputValue(newValue);
      onChange(newValue);
  
      const cursorPosition = event.target.selectionStart || 0;
      const textBeforeCursor = newValue.slice(0, cursorPosition);
      const words = textBeforeCursor.split(/\s/);
      const currentWord = words[words.length - 1];
  
      setAnchorEl(event.currentTarget);
  
      if (currentWord.startsWith('@')) {
        const search = currentWord.slice(1).toLowerCase();
        const allPeople = [
          ...personnel.map(p => ({ id: p._id, name: p.name })),
          ...users.map(u => ({ id: u._id, name: u.name }))
        ];
        
        const mentionSuggestions = allPeople
          .filter(p => p.name.toLowerCase().includes(search))
          .map(p => ({
            type: 'mention' as const,
            text: `@${p.name}`,
            inputText: `@${p.name.split(' ')[0]}`,
            icon: PersonIcon,
          }));
        setSuggestions(mentionSuggestions);
      } else if (currentWord.startsWith('#')) {
        const search = currentWord.slice(1).toLowerCase();
        const tagSuggestions: Suggestion[] = TAGS.filter(tag => 
          tag.text.toLowerCase().includes(search) ||
          tag.inputText.toLowerCase().includes(search)
        ).map(tag => ({
          type: 'tag',
          text: tag.text,
          inputText: tag.inputText,
          icon: tag.icon,
        }));
        setSuggestions(tagSuggestions);
      } else {
        setSuggestions([]);
      }
    };
  
    const handleSuggestionClick = (suggestion: Suggestion) => {
      const words = inputValue.split(/\s/);
      words[words.length - 1] = suggestion.text;
      const newValue = words.join(' ') + ' ';
      setInputValue(newValue);
      onChange(newValue);
      setSuggestions([]);
      setAnchorEl(null);
      
      setTimeout(() => {
        const input = document.querySelector('input[placeholder="Add task... (@mention #urgent)"]') as HTMLInputElement;
        if (input) {
          input.focus();
          input.setSelectionRange(newValue.length, newValue.length);
        }
      }, 0);
    };
  
    const handleKeyDown = (event: React.KeyboardEvent) => {
      if (event.key === 'Enter' && !suggestions.length) {
        event.preventDefault();
        onSubmit();
      } else if (event.key === 'Tab' && suggestions.length) {
        event.preventDefault();
        handleSuggestionClick(suggestions[0]);
      }
    };
  
    return (
      <Box sx={{ position: 'relative', width: '100%' }}>
        <TextField
          fullWidth
          size="small"
          value={inputValue}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
          placeholder="Add task... (@mention #urgent)"
        />
        <Popper
          open={Boolean(suggestions.length)}
          anchorEl={anchorEl}
          placement="bottom-start"
          style={{ width: anchorEl?.offsetWidth, zIndex: 1400 }}
        >
          <Paper elevation={3} sx={{ mt: 1 }}>
            {suggestions.map((suggestion, index) => (
              <Box
                key={index}
                sx={{
                  p: 1,
                  display: 'flex',
                  alignItems: 'center',
                  gap: 1,
                  cursor: 'pointer',
                  '&:hover': { bgcolor: 'action.hover' },
                }}
                onClick={() => handleSuggestionClick(suggestion)}
              >
                <suggestion.icon fontSize="small" />
                <Typography variant="body2">{suggestion.text}</Typography>
              </Box>
            ))}
          </Paper>
        </Popper>
      </Box>
    );
  };
  
const QuickTasks: React.FC = () => {
  const [tasks, setTasks] = useState<Task[]>([]);
  const [newTask, setNewTask] = useState<string>('');
  const [selectedStrand, setSelectedStrand] = useState<string>('all');
  const [selectedEvent, setSelectedEvent] = useState<string>('all');
  const [selectedPerformance, setSelectedPerformance] = useState<string>('all');

  const { data: currentUser } = useQuery({
    queryKey: ['currentUser'],
    queryFn: async () => {
      const response = await axiosInstance.get('/api/users/me');
      return response.data;
    },
  });
  
  const { data: tasksList = [] } = useQuery<Task[]>({
    queryKey: ['tasks', selectedStrand], // Add selectedStrand to query key
    queryFn: async () => {
      const endpoint = selectedStrand === 'all' 
        ? '/api/tasks'
        : `/api/tasks?strandId=${selectedStrand}`;
      const response = await axiosInstance.get(endpoint);
      return response.data;
    },
    enabled: !!currentUser, // Only run query when we have a current user
  });
  
  // Replace the tasks state with the filtered results
  useEffect(() => {
    setTasks(tasksList);
  }, [tasksList, selectedStrand]);



  const { data: strands = [] } = useQuery<Strand[]>({
    queryKey: ['strands'],
    queryFn: async () => {
      const response = await axiosInstance.get('/api/strands');
      return response.data;
    },
  });

  const { data: events = [] } = useQuery<Event[]>({
    queryKey: ['events', selectedStrand],
    queryFn: async () => {
      if (selectedStrand === 'all') return [];
      const response = await axiosInstance.get(`/api/events?strandId=${selectedStrand}`);
      return response.data;
    },
    enabled: selectedStrand !== 'all',
  });

  const { data: performances = [] } = useQuery<Performance[]>({
    queryKey: ['performances', selectedEvent],
    queryFn: async () => {
      if (selectedEvent === 'all') return [];
      const response = await axiosInstance.get(`/api/performances?eventId=${selectedEvent}`);
      return response.data;
    },
    enabled: selectedEvent !== 'all',
  });

  const { data: personnel = [] } = useQuery<Personnel[]>({
    queryKey: ['personnel'],
    queryFn: async () => {
      const response = await axiosInstance.get('/api/personnel');
      console.log('Personnel response:', response.data);  // Add this log
      return response.data;
    },
    enabled: !!currentUser,  // Only fetch when we have a user
});

    // Add query for users
    const { data: users = [] } = useQuery<User[]>({
        queryKey: ['users'],
        queryFn: async () => {
          const response = await axiosInstance.get('/api/users');
          return response.data;
        },
      });

       // Create a map of all possible assignees combining personnel and users
// Create a map of all possible assignees combining personnel and users
const assigneeMap = useMemo(() => {
    // Don't create map until we have both data sources
    if (!personnel?.length && !users?.length) return new Map();
    
    const map = new Map<string, string>();
    
    personnel?.forEach((p: Personnel) => {
      map.set(p._id, p.name);
    });
    
    users?.forEach((u: User) => {
      if (!map.has(u._id)) {
        map.set(u._id, u.name);
      }
    });
    
    return map;
}, [personnel, users]);
  
  const getAssigneeName = (assignedToId?: string | any): string => {
    if (!assignedToId) return 'Unassigned';
    
    // If assignedToId is an object with _id property, use that
    const id = typeof assignedToId === 'object' ? assignedToId._id : assignedToId;
    
    console.log('Looking up ID:', id, 'in map:', Array.from(assigneeMap.entries()));
    return assigneeMap.get(id) || 'Unknown';
};
  // Sort tasks into active and completed
  const { activeTasks, completedTasks } = useMemo(() => {
    return tasks.reduce(
      (acc, task) => {
        if (task.completed) {
          acc.completedTasks.push(task);
        } else {
          acc.activeTasks.push(task);
        }
        return acc;
      },
      { activeTasks: [] as Task[], completedTasks: [] as Task[] }
    );
  }, [tasks]);

  // Add function to delete all completed tasks
  const deleteCompletedTasks = async () => {
    try {
      const deletePromises = completedTasks.map(task => 
        axiosInstance.delete(`/api/tasks/${task._id}`)
      );
      await Promise.all(deletePromises);
      setTasks(tasks.filter(task => !task.completed));
    } catch (error) {
      console.error('Failed to delete completed tasks:', error);
    }
  };
  

  useEffect(() => {
    setSelectedEvent('all');
    setSelectedPerformance('all');
  }, [selectedStrand]);

  useEffect(() => {
    setSelectedPerformance('all');
  }, [selectedEvent]);

  const parseTaskInput = (input: string): Partial<Task> => {
    const task: Partial<Task> = {
      text: input, // Save full text including hashtags
      completed: false,
      urgent: input.includes('#urgent'),
      created: new Date(),
      assignedTo: currentUser?._id,
      createdBy: currentUser?._id, // Add this to match schema
    };
  
  
    const assignMatch = input.match(/@(\w+\s*\w*)/);
    if (assignMatch) {
      const mentionedName = assignMatch[1].toLowerCase();
      
      // Check both personnel and users
      const assignedPerson = personnel.find(p => 
        p.name.toLowerCase().includes(mentionedName)
      );
      
      const assignedUser = users.find(u => 
        u.name.toLowerCase().includes(mentionedName)
      );
  
      if (assignedPerson) {
        task.assignedTo = assignedPerson._id;
      } else if (assignedUser) {
        task.assignedTo = assignedUser._id;
      }
  
      input = input.replace(/@\w+\s*\w*/, '').trim();
    }
  
    task.text = input.replace(/#urgent|#priority|#caitriona|#louis|#bianca|#cooler|#aoife|#programming|#phone|#email/,'').trim();
    
    if (selectedStrand !== 'all') task.strandId = selectedStrand;
    if (selectedEvent !== 'all') task.eventId = selectedEvent;
    if (selectedPerformance !== 'all') task.performanceId = selectedPerformance;
  
    return task;
  };

  const addTask = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!newTask.trim()) return;

    const task = parseTaskInput(newTask);
    
    try {
      const response = await axiosInstance.post('/api/tasks', task);
      setTasks(prev => [response.data, ...prev]);
      setNewTask('');
    } catch (error) {
      console.error('Failed to create task:', error);
    }
  };

  const toggleComplete = async (taskId: string) => {
    try {
      const task = tasks.find(t => t._id === taskId);
      if (!task) return;

      const response = await axiosInstance.patch(`/api/tasks/${taskId}`, {
        completed: !task.completed
      });

      setTasks(tasks.map(task => 
        task._id === taskId ? response.data : task
      ));
    } catch (error) {
      console.error('Failed to update task:', error);
    }
  };

  const deleteTask = async (taskId: string) => {
    try {
      await axiosInstance.delete(`/api/tasks/${taskId}`);
      setTasks(tasks.filter(task => task._id !== taskId));
    } catch (error) {
      console.error('Failed to delete task:', error);
    }
  };

  return (
    <Paper sx={{ p: 3, maxWidth: 800, mx: 'auto', my: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 3 }}>
        <Typography variant="h5">Quick Tasks</Typography>
        {completedTasks.length > 0 && (
          <Button
            variant="outlined"
            color="secondary"
            startIcon={<DeleteSweepIcon />}
            onClick={deleteCompletedTasks}
            size="small"
          >
            Delete Completed ({completedTasks.length})
          </Button>
        )}
      </Box>

      <form onSubmit={addTask}>
        <Stack spacing={2}>
          <Box sx={{ display: 'flex', gap: 2 }}>
            <FormControl size="small" sx={{ minWidth: 200 }}>
              <InputLabel>Strand</InputLabel>
              <Select
                value={selectedStrand}
                onChange={(e) => setSelectedStrand(e.target.value)}
                label="Strand"
              >
                <MenuItem value="all">All Strands</MenuItem>
                {strands.map((strand: Strand) => (
                  <MenuItem key={strand._id} value={strand._id}>
                    {strand.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {selectedStrand !== 'all' && (
              <FormControl size="small" sx={{ minWidth: 200 }}>
                <InputLabel>Event</InputLabel>
                <Select
                  value={selectedEvent}
                  onChange={(e) => setSelectedEvent(e.target.value)}
                  label="Event"
                >
                  <MenuItem value="all">All Events</MenuItem>
                  {events.map((event: Event) => (
                    <MenuItem key={event._id} value={event._id}>
                      {event.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {selectedEvent !== 'all' && (
              <FormControl size="small" sx={{ minWidth: 200 }}>
                <InputLabel>Performance</InputLabel>
                <Select
                  value={selectedPerformance}
                  onChange={(e) => setSelectedPerformance(e.target.value)}
                  label="Performance"
                >
                  <MenuItem value="all">All Performances</MenuItem>
                  {performances.map((performance: Performance) => (
                    <MenuItem key={performance._id} value={performance._id}>
                      {performance.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
          </Box>

          <Box sx={{ display: 'flex', gap: 1 }}>
            <EnhancedTaskInput
              value={newTask}
              onChange={setNewTask}
              onSubmit={() => addTask({ preventDefault: () => {} } as React.FormEvent)}
              personnel={personnel || []}
              users={users || []}  // Pass users data
            />
            <Button 
              type="submit" 
              variant="contained" 
              size="small"
              startIcon={<AddIcon />}
            >
              Add
            </Button>
          </Box>
        </Stack>
      </form>

      <Stack spacing={1} sx={{ mt: 2 }}>
        {/* Active Tasks */}
        {activeTasks.map((task: Task) => (
          <TaskItem
            key={task._id}
            task={task}
            onToggle={toggleComplete}
            onDelete={deleteTask}
            getAssigneeName={getAssigneeName}
            strands={strands}
          />
        ))}

        {/* Completed Tasks */}
        {completedTasks.length > 0 && (
          <>
            <Box sx={{ 
              borderTop: '1px solid',
              borderColor: 'divider',
              pt: 2,
              mt: 2,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}>
              <Typography 
                variant="body2" 
                color="text.secondary"
                sx={{ flex: 1 }}
              >
                Completed Tasks ({completedTasks.length})
              </Typography>
              <Button
                variant="outlined"
                color="secondary"
                startIcon={<DeleteSweepIcon />}
                onClick={deleteCompletedTasks}
                size="small"
              >
                Clear All
              </Button>
            </Box>
            {completedTasks.map((task: Task) => (
              <TaskItem
                key={task._id}
                task={task}
                onToggle={toggleComplete}
                onDelete={deleteTask}
                getAssigneeName={getAssigneeName}
                strands={strands}
              />
            ))}
          </>
        )}
      </Stack>
    </Paper>
  );
};

 // Separate TaskItem component for better organization
 const TaskItem: React.FC<{
  task: Task;
  onToggle: (id: string) => void;
  onDelete: (id: string) => void;
  getAssigneeName: (id?: string) => string;
  strands: Strand[];
}> = ({ task, onToggle, onDelete, getAssigneeName, strands }) => {
  const hashtags = task.text.match(/#\w+/g) || [];
  const cleanText = task.text.replace(/#\w+/g, '').trim();

  return (
      <Paper
          variant="outlined"
          sx={{
              p: 1,
              display: 'flex',
              alignItems: 'center',
              gap: 1,
              bgcolor: task.completed ? 'action.hover' : 'background.paper',
              opacity: task.completed ? 0.8 : 1,
          }}
      >
          <Checkbox
              checked={task.completed}
              onChange={() => onToggle(task._id)}
              size="small"
          />
          
          <Box sx={{ flex: 1 }}>
              <Typography
                  variant="body1"
                  sx={{
                      textDecoration: task.completed ? 'line-through' : 'none',
                      color: task.completed ? 'text.secondary' : 'text.primary'
                  }}
              >
                  {cleanText}
              </Typography>
              
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5, my: 1 }}>
                  {hashtags.map((tag, index) => (
                      <Chip
                          key={index}
                          label={tag}
                          size="small"
                          color={tag === '#urgent' ? 'error' : 
                                 tag === '#priority' ? 'warning' :
                                 tag === '#cooler' ? 'info' : 'primary'}
                          variant="outlined"
                      />
                  ))}
              </Box>
              
              <Typography variant="caption" color="text.secondary" sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                  <span>
                      Owner: {getAssigneeName(task.assignedTo)}
                  </span>
                  {task.strandId && (
                      <span>
                          Strand: {strands.find((s: Strand) => s._id === task.strandId)?.name || 'General'}
                      </span>
                  )}
                  <Box sx={{ display: 'flex', alignItems: 'center', gap: 0.5 }}>
                      <CalendarIcon fontSize="inherit" />
                      {new Date(task.created).toLocaleDateString()}
                  </Box>
              </Typography>
          </Box>

          <IconButton
              size="small"
              onClick={() => onDelete(task._id)}
              sx={{ color: 'text.secondary' }}
          >
              <DeleteIcon fontSize="small" />
          </IconButton>
      </Paper>
  );
};

  export default QuickTasks;