import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container, Typography, Box } from '@mui/material';
import axios from 'axios';
import axiosInstance from '../utils/axiosConfig';
import PerformanceForm from '../components/performance/form/index';
import LoadingSpinner from '../components/LoadingSpinner';
import ErrorMessage from '../components/ErrorMessage';
import { Strand } from '../types/Strand';
import { Venue } from '../types/mongodb';

interface Event {
  _id: string;
  name: string;
}

interface PerformanceCosts {
  bandFee?: number;
  travel?: {
    flights: number;
    groundTransport: number;
    other: number;
    total: number;
  };
  accommodation?: {
    singleRooms: {
      quantity: number;
      costPerRoom: number;
      totalNights: number;
    };
    twinRooms: {
      quantity: number;
      costPerRoom: number;
      totalNights: number;
    };
    total: number;
  };
  perDiem?: {
    dailyRate: number;
    numberOfDays: number;
    total: number;
  };
  equipment?: {
    rental: number;
    shipping: number;
    backline: number;
    total: number;
  };
  technical?: {
    soundEngineer: number;
    lightingEngineer: number;
    backline: number;
    other: number;
    total: number;
  };
  additional?: {
    marketing?: number;
    insurance?: number;
    visas?: number;
    total: number;
  };
  otherCosts?: Array<{ description: string; amount: number }>;
  summary?: {
    totalCosts: number;
    contingency: number;
    grandTotal: number;
  };
  currency?: string;
}

interface Performance {
  _id: string;
  band?: string;
  simpleBandName?: string;
  startTime: string;
  endTime: string;
  fee: string;
  status: string;
  costs: PerformanceCosts;
  contingencyRate: string;
  notes: string;
  event: Event;
  eventId: string;
  strandId?: string | Strand;  // Can be either string ID or populated Strand object
  venue?: string | Venue;
  ticketConfigId?: string;
  ticketing?: any;
}


const AddEditPerformancePage: React.FC = () => {
  const { eventId, id, strandId } = useParams<{ eventId?: string; id?: string; strandId?: string }>();
  const navigate = useNavigate();
  const [event, setEvent] = useState<Event | null>(null);
  const [performance, setPerformance] = useState<Performance | null>(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      if (!id && !eventId) return; // Don't fetch anything for new performance without event

      setLoading(true);
      try {
        if (id) {
          const performanceResponse = await axiosInstance.get(`/api/performances/${id}`);
          console.log('Raw performance data:', performanceResponse.data);
          setPerformance(performanceResponse.data);
          
          if (performanceResponse.data.eventId) {
            const eventResponse = await axiosInstance.get(`/api/events/${performanceResponse.data.eventId}`);
            setEvent(eventResponse.data);
          }
        }
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to fetch data. Please try again.');
      } finally {
        setLoading(false);
      }
    };
    fetchData();
  }, [id, eventId]);


  const handleSubmit = async (performanceData: any) => {
    try {
      const cleanedData = { ...performanceData };
      
      // Remove empty strings and undefined values
      Object.keys(cleanedData).forEach(key => {
        if (cleanedData[key] === '' || cleanedData[key] === undefined) {
          delete cleanedData[key];
        }
      });
  
      if (id) {
        // For updates, only send modified fields
        await axiosInstance.put(`/api/performances/${id}`, cleanedData);
      } else {
        // For new performances
        const response = await axiosInstance.post(`/api/performances`, cleanedData);
      }
      navigate(`/admin/performances`);
    } catch (err) {
      console.error('Error submitting performance:', err);
      setError('Failed to submit performance. Please try again.');
    }
  };

  if (loading) return <LoadingSpinner />;
  if (error) return <ErrorMessage message={error} />;
  if (id && !performance) return <ErrorMessage message="Performance not found." />;

  return (
    <Container>
      <Box my={4}>
        <Typography variant="h4" component="h1" gutterBottom>
          {id ? 'Edit' : 'Add'} Performance {event?.name ? `for ${event.name}` : ''}
        </Typography>
        <PerformanceForm
          eventId={eventId}
          strandId={strandId || (performance?.strandId && (performance.strandId as Strand)._id)}
          onSubmit={handleSubmit}
          initialData={performance ? {
            ...performance,
            strandId: typeof performance.strandId === 'object' ? 
            performance.strandId._id : 
            performance.strandId,
            fee: performance.costs.bandFee?.toString() || '',  // Map from correct location
            startTime: new Date(performance.startTime), // Ensure these are properly parsed
            endTime: new Date(performance.endTime),
            status: performance.status as "pending" | "confirmed" | "cancelled",
            band: performance.band || '',
            simpleBandName: performance.simpleBandName || '',
            venue: typeof performance.venue === 'object' ? 
            performance.venue._id : 
            performance.venue,
            ticketConfigId: performance.ticketConfigId || undefined,
            ticketing: performance.ticketing || { config: null },
            costs: {
              contingencyRate: parseFloat(performance.contingencyRate?.toString() || '10'), // Move it here
              bandFee: performance.costs.bandFee || 0,  // Add this line
              travel: {
                flights: performance.costs?.travel?.flights || 0,
                groundTransport: performance.costs?.travel?.groundTransport || 0,
                other: performance.costs?.travel?.other || 0,
                total: performance.costs?.travel?.total || 0,
              },
              accommodation: {
                singleRooms: {
                  quantity: performance.costs?.accommodation?.singleRooms?.quantity || 0,
                  costPerRoom: performance.costs?.accommodation?.singleRooms?.costPerRoom || 0,
                  totalNights: performance.costs?.accommodation?.singleRooms?.totalNights || 0,
                },
                twinRooms: {
                  quantity: performance.costs?.accommodation?.twinRooms?.quantity || 0,
                  costPerRoom: performance.costs?.accommodation?.twinRooms?.costPerRoom || 0,
                  totalNights: performance.costs?.accommodation?.twinRooms?.totalNights || 0,
                },
                total: performance.costs?.accommodation?.total || 0,
              },
              perDiem: {
                dailyRate: performance.costs?.perDiem?.dailyRate || 0,
                numberOfDays: performance.costs?.perDiem?.numberOfDays || 0,
                total: performance.costs?.perDiem?.total || 0,
              },
              equipment: {
                rental: performance.costs?.equipment?.rental || 0,
                shipping: performance.costs?.equipment?.shipping || 0,
                backline: performance.costs?.equipment?.backline || 0,
                total: performance.costs?.equipment?.total || 0,
              },
              technical: {
                soundEngineer: performance.costs?.technical?.soundEngineer || 0,
                lightingEngineer: performance.costs?.technical?.lightingEngineer || 0,
                backline: performance.costs?.technical?.backline || 0,
                other: performance.costs?.technical?.other || 0,
                total: performance.costs?.technical?.total || 0,
              },
              additional: {
                marketing: performance.costs?.additional?.marketing ?? 0,
                insurance: performance.costs?.additional?.insurance ?? 0,
                visas: performance.costs?.additional?.visas ?? 0,
                total: performance.costs?.additional?.total || 0,
              },
              otherCosts: performance.costs?.otherCosts || [],
              summary: {
                totalCosts: performance.costs?.summary?.totalCosts || 0,
                contingency: performance.costs?.summary?.contingency || 0,
                grandTotal: performance.costs?.summary?.grandTotal || 0,
              },
              currency: performance.costs?.currency || 'EUR',
            }
          } : undefined}
          isEdit={!!id}
        />
      </Box>
    </Container>
  );
};

export default AddEditPerformancePage;