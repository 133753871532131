import React from 'react';
import { Box, Container, Breadcrumbs, Typography, IconButton, Link } from '@mui/material';
import { ArrowLeft } from 'lucide-react';
import PersonnelDashboard from '../components/user/PersonnelDashboard';

const PersonnelDashboardPage = () => {
  const handleBack = () => {
    window.history.back();
  };

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>
      {/* Header Section */}
      <Box sx={{ 
        py: 2, 
        px: 3, 
        borderBottom: '1px solid', 
        borderColor: 'divider',
        bgcolor: 'background.paper'
      }}>
        <Container maxWidth="lg">
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            gap: 2 
          }}>
            <IconButton onClick={handleBack} size="small">
              <ArrowLeft />
            </IconButton>
            
            <Breadcrumbs aria-label="breadcrumb">
              <Link color="inherit" href="/admin">
                Admin
              </Link>
              <Link color="inherit" href="/admin/personnel">
                Personnel
              </Link>
              <Typography color="text.primary">Dashboard</Typography>
            </Breadcrumbs>
          </Box>
        </Container>
      </Box>

      {/* Main Content */}
      <Box sx={{ py: 4 }}>
        <PersonnelDashboard />
      </Box>
    </Box>
  );
};

export default PersonnelDashboardPage;