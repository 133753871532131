import React from 'react';
import { Box, Typography, Tooltip, IconButton } from '@mui/material';
import { Copy } from 'lucide-react';

interface TitoSlugProps {
  performance: {
    simpleBandName?: string;
    band?: {
      artistName?: string;
    } | string;
    startTime: string;
    venue?: {
      name?: string;
    } | string;
  };
}

const TitoSlug: React.FC<TitoSlugProps> = ({ performance }) => {
  const cleanText = (text: string) => {
    return text
      .toLowerCase()
      .replace(/^the\s+/i, '')
      .replace(/\s+the\s+/g, ' ')
      .replace(/['']/g, '')
      .replace(/&/g, 'and')
      .replace(/[^a-z0-9-]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const generateSlug = () => {
    const artistName = performance.simpleBandName || 
      (typeof performance.band === 'object' ? performance.band?.artistName : '');
    const venueName = typeof performance.venue === 'object' ? 
      performance.venue?.name?.toLowerCase() : 'cooler';
    const date = new Date(performance.startTime);
    const month = date.toLocaleString('en-US', { month: 'short' }).toLowerCase();
    const year = date.getFullYear().toString().slice(-2);
    const cleanArtistName = cleanText(artistName || '');
    const cleanVenueName = cleanText(venueName || '');
    return `${cleanArtistName}-${cleanVenueName}-${month}${year}`;
  };

  const slug = generateSlug();

  const copyToClipboard = () => {
    navigator.clipboard.writeText(slug);
  };

  return (
    <Box>
      <Typography variant="caption" gutterBottom>
        Tito Event Slug
      </Typography>
      <Box sx={{ 
        display: 'flex', 
        alignItems: 'center', 
        gap: 1,
        mt: 0.1
      }}>
        <Tooltip title="Copy to clipboard">
          <IconButton 
            onClick={copyToClipboard}
            size="small"
            className="text-gray-500 hover:text-gray-700"
          >
            <Copy className="h-4 w-4" />
          </IconButton>
        </Tooltip>
        <Typography className="font-mono text-sm">
          {slug}
        </Typography>
      </Box>
    </Box>
  );
};

export default TitoSlug;