import axios, { AxiosInstance, AxiosError } from 'axios';
import { toast } from 'react-toastify';

// Type for API Error Response
interface ApiErrorResponse {
  message: string;
  status: number;
}

// Create axios instance
const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: true,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Request interceptor
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token');
    
    // More detailed request logging
    console.log('🚀 Request:', {
      url: config.url,
      method: config.method,
      headers: {
        ...config.headers,
        Authorization: token ? 'Bearer [REDACTED]' : 'None'
      },
      timestamp: new Date().toISOString()
    });

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
      // Ensure authorization header is set correctly
      console.log('🔐 Token applied to request');
    } else {
      console.warn('⚠️ No token found in localStorage');
    }
    
    return config;
  },
  (error: AxiosError) => {
    console.error('❌ Request Interceptor Error:', {
      message: error.message,
      config: error.config
    });
    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  (response) => {
    // More detailed success logging
    console.log('✅ Response:', {
      status: response.status,
      url: response.config.url,
      method: response.config.method,
      timestamp: new Date().toISOString(),
      hasData: !!response.data
    });
    
    return response;
  },
  async (error: AxiosError<ApiErrorResponse>) => {
    // Enhanced error logging
    console.error('❌ Response Error:', {
      status: error.response?.status,
      url: error.config?.url,
      method: error.config?.method,
      message: error.response?.data?.message || error.message,
      timestamp: new Date().toISOString()
    });

    // Handle authentication errors
    if (error.response?.status === 401) {
      // Try to refresh token first if it's not a login/refresh request
      if (!error.config?.url?.includes('/auth/')) {
        try {
          const refreshResponse = await axios.post(
            `${process.env.REACT_APP_API_URL}/api/auth/refresh-token`,
            {},
            { withCredentials: true }
          );

          if (refreshResponse.data.token) {
            localStorage.setItem('token', refreshResponse.data.token);
            // Retry the original request with new token
            if (error.config) {
              error.config.headers.Authorization = `Bearer ${refreshResponse.data.token}`;
              return axiosInstance(error.config);
            }
          }
        } catch (refreshError) {
          console.error('Token refresh failed:', refreshError);
        }
      }

      // If we get here, token refresh failed or wasn't attempted
      localStorage.removeItem('token');
      const currentPath = window.location.pathname;
      const returnPath = currentPath !== '/login' ? currentPath : '/';
      
      toast.error('Your session has expired. Please log in again.');
      window.location.href = `/login?returnTo=${encodeURIComponent(returnPath)}&sessionExpired=true`;
      return Promise.reject(new Error('Session expired'));
    }

    // Handle other errors
    const errorMessage = error.response?.data?.message || 
                        error.message || 
                        'An unexpected error occurred';

    if (error.response) {
      switch (error.response.status) {
        case 403:
          toast.error('You don\'t have permission to perform this action');
          break;
        case 404:
          toast.error('The requested resource was not found');
          break;
        case 429:
          toast.error('Too many requests. Please try again later');
          break;
        case 500:
        case 502:
        case 503:
          toast.error('A server error occurred. Please try again later');
          break;
        default:
          toast.error(errorMessage);
      }
    }

    return Promise.reject(error);
  }
);

export default axiosInstance;

// Helper function to handle API errors
export const handleApiError = (error: unknown): string => {
  if (axios.isAxiosError(error)) {
    const message = error.response?.data?.message || error.message;
    toast.error(message);
    return message;
  }
  const defaultMessage = 'An unexpected error occurred';
  toast.error(defaultMessage);
  return defaultMessage;
};