import React, { useState, useEffect, useCallback, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { 
  Container, 
  Typography, 
  Box, 
  Button, 
  CircularProgress, 
  Grid, 
  Accordion, 
  AccordionSummary, 
  AccordionDetails, 
  Alert 
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import axios from 'axios';
import axiosInstance from '../utils/axiosConfig';

import BandEventInfo from '../components/performance/BandEventInfo';
import PerformanceRevenue from '../components/performance/PerformanceRevenue';
import PerformanceCosts from '../components/performance/PerformanceCosts';
import TicketSalesSection from '../components/performance/TicketSalesSection';
import ActionSection from '../components/performance/ActionSection';
import NotesSection from '../components/performance/NotesSection';
import StaffRoster from '../components/performance/StaffRoster';
import TitoSlug from '../components/performance/TitoSlug';
import PerformanceFinancials from '../components/performance/PerformanceFinancials';

import { 
  Performance, 
  BandRecommendation, 
  Event, 
  Venue, 
  Staff 
} from '../types/mongodb';  // Import all types from one place
import { Strand } from '../types/Strand';


interface Technical {
  soundEngineer: number;
  lightingEngineer: number;
  backline: number;
  other: number;
  total: number;
}

interface PerformanceCosts {
  bandFee: number;
  travel: {
    flights: number;
    groundTransport: number;
    other: number;
    total: number;
  };
  accommodation: {
    singleRooms: {
      quantity: number;
      costPerRoom: number;
      totalNights: number;
    };
    twinRooms: {
      quantity: number;
      costPerRoom: number;
      totalNights: number;
    };
    total: number;
  };
  perDiem: {
    dailyRate: number;
    numberOfDays: number;
    total: number;
  };
  equipment: {
    rental: number;
    shipping: number;
    backline: number;
    total: number;
  };
  technical: {
    soundEngineer: number;
    lightingEngineer: number;
    backline: number;
    other: number;
    total: number;
  };
  additional: {
    marketing: number;
    insurance: number;
    visas: number;
    total: number;
  };
  contingencyRate: number;
  summary: {
    totalCosts: number;
    contingency: number;
    grandTotal: number;
  };
}

interface Revenue {
  ticketPrice: number;
  expectedOccupancy: number;
  sponsorship: number;
  merchandiseSales: number;
  otherRevenue: number;
}


interface CostBreakdown {
  fee: number;
  travel: number;
  accommodation: number;
  perDiem: number;
  equipment: number;
  other: number;
  contingency: number;
}


const isFullStrand = (strand: string | Strand | undefined): strand is Strand => {
  return typeof strand === 'object' && strand !== null && 'startDate' in strand;
};

const PerformanceDetailPage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const navigate = useNavigate();
  const [performance, setPerformance] = useState<Performance | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [totalCost, setTotalCost] = useState<number>(0);
  const [totalIncome, setTotalIncome] = useState<number>(0);
  const [profitLoss, setProfitLoss] = useState<number>(0);
  const [ticketsSold, setTicketsSold] = useState<number>(0);
  const [ticketSales, setTicketSales] = useState<number>(0);
  const [costBreakdown, setCostBreakdown] = useState<CostBreakdown>({
    fee: 0,
    travel: 0,
    accommodation: 0,
    perDiem: 0,
    equipment: 0,
    other: 0,
    contingency: 0,
  });
  const [ticketConfig, setTicketConfig] = useState<{
    ticketTypes: Array<{
      name: string;
      quantity: number;
      price: number;
      vatRate: number;
      vatExempt: boolean;
      revenueImpact: boolean;
    }>;
  } | null>(null);
   const assignStaffRef = useRef<HTMLDivElement | null>(null);

  const handleScrollToStaff = () => {
    assignStaffRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  };

  const calculateTravelTotal = (travel: any) => {
    return (travel?.flights || 0) + 
           (travel?.groundTransport || 0) + 
           (travel?.other || 0);
  };
  
  const calculateAccommodationTotal = (accommodation: any) => {
    return ((accommodation?.singleRooms || 0) + 
            (accommodation?.twinRooms || 0)) * 
           (accommodation?.costPerRoom || 0) * 
           (accommodation?.totalNights || 1);
  };
  
  const calculatePerDiemTotal = (perDiem: any) => {
    return (perDiem?.dailyRate || 0) * 
           (perDiem?.numberOfDays || 1);
  };
  
  const calculateEquipmentTotal = (equipment: any) => {
    return (equipment?.rental || 0) + 
           (equipment?.shipping || 0);
  };


  const calculateTotalCost = (data: Performance): number => {
    const { costs } = data;
    const baseCosts = 
      (costs.bandFee || 0) +
      (costs.travel?.flights || 0) +
      (costs.travel?.groundTransport || 0) +
      (costs.travel?.other || 0) +
      ((costs.accommodation?.singleRooms || 0) + (costs.accommodation?.twinRooms || 0) * 2) * 
        (costs.accommodation?.costPerRoom || 0) * (costs.accommodation?.totalNights || 1) +
      (costs.perDiem?.dailyRate || 0) * (costs.perDiem?.numberOfDays || 1) +
      (costs.equipment?.rental || 0) +
      (costs.equipment?.shipping || 0) +
      (costs.marketing || 0) +
      (costs.insurance || 0) +
      (costs.visas || 0) +
      (costs.other || 0);

    const technicalCosts = 
      (costs.technical?.soundEngineer || 0) +
      (costs.technical?.lightingEngineer || 0) +
      (costs.technical?.backline || 0) +
      (costs.technical?.other || 0);

    return baseCosts + technicalCosts;
  };

  const calculateTotalIncome = (data: Performance): number => {
    const { revenue } = data;
    return (revenue.ticketPrice || 0) * (revenue.expectedOccupancy || 0) +
           (revenue.sponsorship || 0) +
           (revenue.merchandiseSales || 0) +
           (revenue.otherRevenue || 0);
  };

  const fetchPerformance = useCallback(async () => {
    if (!id) return;
    
    try {
      setLoading(true);
      const response = await axiosInstance.get<Performance>(
        `/api/performances/${id}?populate=staff,strand`
      );
      setPerformance(response.data);
      updateFinancials(response.data);
      setError(null);
    } catch (error) {
      console.error('Error fetching performance:', error);
      setError('Failed to fetch performance details');
    } finally {
      setLoading(false);
    }
  }, [id]);

  const fetchTicketConfig = useCallback(async () => {
    if (!id) return;
    
    try {
      const response = await axiosInstance.get(
        `/api/ticket-configs/search`, {
          params: {
            performanceId: id,
            selectionMethod: 'event'
          }
        }
      );
      setTicketConfig(response.data);
    } catch (error) {
      console.error('Error fetching ticket config:', error);
    }
  }, [id]);

  useEffect(() => {
    fetchPerformance();
    fetchTicketConfig();
  }, [fetchPerformance, fetchTicketConfig]);



  const updateFinancials = (data: Performance) => {
    const cost = calculateTotalCost(data);
    const contingencyAmount = cost * ((data.contingencyRate || 0) / 100);
    const totalWithContingency = cost + contingencyAmount;
    
    setTotalCost(totalWithContingency);
    
    const income = calculateTotalIncome(data);
    setTotalIncome(income);
    setProfitLoss(income - totalWithContingency);

    const technicalCosts = 
      (data.costs.technical?.soundEngineer || 0) + 
      (data.costs.technical?.lightingEngineer || 0) + 
      (data.costs.technical?.backline || 0) + 
      (data.costs.technical?.other || 0);

    setCostBreakdown({
      fee: data.costs.bandFee || 0,
      travel: (data.costs.travel?.flights || 0) + 
              (data.costs.travel?.groundTransport || 0) + 
              (data.costs.travel?.other || 0),
      accommodation: ((data.costs.accommodation?.singleRooms || 0) + 
                     (data.costs.accommodation?.twinRooms || 0) * 2) * 
                    (data.costs.accommodation?.costPerRoom || 0) * 
                    (data.costs.accommodation?.totalNights || 1),
      perDiem: (data.costs.perDiem?.dailyRate || 0) * 
               (data.costs.perDiem?.numberOfDays || 1),
      equipment: (data.costs.equipment?.rental || 0) + 
                (data.costs.equipment?.shipping || 0),
      other: (data.costs.marketing || 0) + 
             (data.costs.insurance || 0) + 
             (data.costs.visas || 0) + 
             (data.costs.other || 0) + 
             technicalCosts,
      contingency: contingencyAmount
    });

    setTicketsSold(Math.floor(data.revenue.expectedOccupancy || 0));
    setTicketSales((data.revenue.ticketPrice || 0) * (data.revenue.expectedOccupancy || 0));
  };

  const handleStaffAssignment = async (updatedPerformance: Performance) => {
    setPerformance(prev => {
      if (!prev) return updatedPerformance;
      return { ...prev, staff: updatedPerformance.staff };
    });
    updateFinancials(updatedPerformance);
  };

  const handleUpdate = (updatedPerformance: Performance) => {
    setPerformance(updatedPerformance);
    updateFinancials(updatedPerformance);
  };

  const handleSave = async () => {
    if (!performance) return;

    try {
      const response = await axiosInstance.put<Performance>(
        `/api/performances/${performance._id}`,
        performance
      );
      setPerformance(response.data);
      updateFinancials(response.data);
      alert('Changes saved successfully.');
    } catch (error) {
      console.error('Error saving changes:', error);
      alert('Failed to save changes.');
    }
  };

  if (loading) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <CircularProgress />
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="md" sx={{ textAlign: 'center', mt: 4 }}>
        <Typography color="error">{error}</Typography>
      </Container>
    );
  }

  if (!performance) {
    return null;
  }

  const hasStrand = performance.strandId !== undefined;

  return (
    <Container maxWidth="lg" sx={{ mt: 4 }}>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'flex-start', mb: 3 }}>
        <Typography variant="h4" gutterBottom>
          Performance Details
        </Typography>
        <TitoSlug performance={performance} />
      </Box>

      {!hasStrand && (
        <Alert severity="info" sx={{ mb: 3 }}>
          This performance is not currently assigned to any strand.
        </Alert>
      )}
           


<Grid container spacing={4}>
  <Grid item xs={12} md={8}>
    <BandEventInfo 
      performance={{ 
        ...performance, 
        band: performance.band || '',
        event: performance.event || ''
      }}
    />

  </Grid>
  <Grid item xs={12} md={4}>
    <ActionSection 
      performance={performance} 
      onManageStaffClick={handleScrollToStaff}
    />
  </Grid>
</Grid>
      {performance && ticketConfig && (
  <Grid item xs={12}>
    <PerformanceFinancials
      performance={{
        contingencyRate: performance.costs.contingencyRate || 10, // Add this at root level
        costs: {
          bandFee: performance.costs.bandFee || 0,
          travel: {
            flights: 0,
            groundTransport: 0,
            other: 0,
            total: 0
          },
          accommodation: {
            total: 0
          },
          perDiem: {
            total: 0
          },
          equipment: {
            total: 0
          },
          technical: {
            total: (performance.costs.technical?.soundEngineer || 0) + 
                   (performance.costs.technical?.lightingEngineer || 0) + 
                   (performance.costs.technical?.backline || 0)
          },
          additional: {
            marketing: performance.costs.marketing || 0,
            insurance: performance.costs.insurance || 0,
            visas: performance.costs.visas || 0,
            total: performance.costs.marketing || 0  // Include marketing in total
          },
          summary: {
            totalCosts: performance.costs.bandFee + 
                       (performance.costs.technical?.soundEngineer || 0) + 
                       (performance.costs.technical?.lightingEngineer || 0) + 
                       (performance.costs.technical?.backline || 0) +
                       (performance.costs.marketing || 0),
            contingency: (performance.costs.bandFee + 
                         (performance.costs.technical?.soundEngineer || 0) + 
                         (performance.costs.technical?.lightingEngineer || 0) + 
                         (performance.costs.technical?.backline || 0) +
                         (performance.costs.marketing || 0)) * (performance.contingencyRate || 10) / 100,
            grandTotal: (performance.costs.bandFee + 
                        (performance.costs.technical?.soundEngineer || 0) + 
                        (performance.costs.technical?.lightingEngineer || 0) + 
                        (performance.costs.technical?.backline || 0) +
                        (performance.costs.marketing || 0)) * (1 + (performance.contingencyRate || 10) / 100)
          }
        }
      }}
      ticketConfig={ticketConfig}
    />
  </Grid>
)}

      <Grid container spacing={4} sx={{ mt: 2 }}>
      <Grid item xs={12} md={12} ref={assignStaffRef}> 
      <StaffRoster 
      performanceId={performance._id}
      onAssignmentComplete={handleStaffAssignment}
    />
  </Grid>
  <Grid item xs={12} md={6} sx={{ width: '100%' }}>
    <NotesSection notes={performance.notes || ''} />
  </Grid>
</Grid>

<Accordion sx={{ mt: 4 }}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography>Financial Details</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={4}>
            <Grid item xs={12} md={6}>
              <PerformanceRevenue 
                performance={performance}
                onUpdate={handleUpdate}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <PerformanceCosts
                performance={performance}
                onUpdate={handleUpdate}
              />
            </Grid>
            <Grid item xs={12}>
              <TicketSalesSection 
                ticketsSold={ticketsSold}
                ticketSales={ticketSales}
                totalIncome={totalIncome}
                totalCost={totalCost}
                costBreakdown={costBreakdown}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Box sx={{ textAlign: 'right', mt: 4 }}>
        <Button
          variant="outlined"
          sx={{ mr: 2 }}
          onClick={() => navigate(`/admin/performances/edit/${performance._id}`)}
        >
          Edit Performance Details
        </Button>
        <Button variant="contained" onClick={handleSave} sx={{ mr: 2 }}>
          Save Changes
        </Button>
        <Button variant="contained" onClick={() => navigate('/admin/performances')}>
          Back to Performances
        </Button>
      </Box>
    </Container>
  );
};

export default PerformanceDetailPage;