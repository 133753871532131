import React, { useState } from 'react';
import { 
  Box, 
  SpeedDial, 
  SpeedDialAction, 
  SpeedDialIcon,
  Dialog,
  DialogTitle,
  DialogContent,
  IconButton,
  Typography,
  Snackbar,
  Alert
} from '@mui/material';
import { Plus, ListChecks, Lightbulb, LayoutDashboard, Music, X } from 'lucide-react';
import QuickTasks from '../../components/QuickTasks';
import QuickIdeaForm from '../../components/ideas/QuickIdeaForm';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';


// Create a client
const queryClient = new QueryClient();

interface DialogProps {
  open: boolean;
  onClose: () => void;
}

const QuickTaskDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Quick Add Task</Typography>
          <IconButton onClick={onClose} size="small">
            <X />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <QueryClientProvider client={queryClient}>
          <QuickTasks />
        </QueryClientProvider>
      </DialogContent>
    </Dialog>
  );
};

const QuickIdeaDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  const [notification, setNotification] = useState({
    open: false,
    message: '',
    severity: 'success' as 'success' | 'error'
  });

  const handleSubmitSuccess = () => {
    setNotification({
      open: true,
      message: 'Idea saved successfully!',
      severity: 'success'
    });
    onClose();
  };

  const handleSubmitError = (error: string) => {
    setNotification({
      open: true,
      message: error || 'Failed to save idea',
      severity: 'error'
    });
  };

  const handleCloseNotification = () => {
    setNotification(prev => ({ ...prev, open: false }));
  };

  return (
    <>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Box display="flex" justifyContent="space-between" alignItems="center">
            <Typography variant="h6">Quick Add Idea</Typography>
            <IconButton onClick={onClose} size="small">
              <X />
            </IconButton>
          </Box>
        </DialogTitle>
        <DialogContent>
          <Box sx={{ mt: 2 }}>
            <QuickIdeaForm
              onSubmit={handleSubmitSuccess}
              onError={handleSubmitError}
            />
          </Box>
        </DialogContent>
      </Dialog>

      <Snackbar
        open={notification.open}
        autoHideDuration={6000}
        onClose={handleCloseNotification}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={handleCloseNotification}
          severity={notification.severity}
          sx={{ width: '100%' }}
        >
          {notification.message}
        </Alert>
      </Snackbar>
    </>
  );
};

const QuickTaskboardDialog: React.FC<DialogProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">Quick Add Taskboard</Typography>
          <IconButton onClick={onClose} size="small">
            <X />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <Typography>Taskboard form coming soon...</Typography>
      </DialogContent>
    </Dialog>
  );
};

const QuickAddButton: React.FC = () => {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [activeDialog, setActiveDialog] = useState<'task' | 'idea' | 'taskboard' | 'band' | null>(null);

  const actions = [
    { 
      icon: <ListChecks size={20} />, 
      name: 'Task', 
      handler: () => setActiveDialog('task') 
    },
    { 
      icon: <Lightbulb size={20} />, 
      name: 'Idea', 
      handler: () => setActiveDialog('idea') 
    },
    { 
      icon: <LayoutDashboard size={20} />, 
      name: 'Taskboard', 
      handler: () => setActiveDialog('taskboard') 
    },
    { 
      icon: <Music size={20} />, 
      name: 'New Band', 
      handler: () => navigate('/submit')
    },
  ];

  const handleClose = () => {
    setActiveDialog(null);
  };

  return (
    <>
      <Box sx={{ position: 'fixed', bottom: 16, right: 16, zIndex: 1000 }}>
        <SpeedDial
          ariaLabel="Quick Add SpeedDial"
          icon={<SpeedDialIcon icon={<Plus />} />}
          onClose={() => setOpen(false)}
          onOpen={() => setOpen(true)}
          open={open}
          direction="up"
          sx={{
            '& .MuiSpeedDial-fab': {
              width: 56,
              height: 56,
              backgroundColor: 'primary.main',
              '&:hover': {
                backgroundColor: 'primary.dark',
              },
            },
          }}
        >
          {actions.map((action) => (
            <SpeedDialAction
              key={action.name}
              icon={action.icon}
              tooltipTitle={action.name}
              onClick={() => {
                setOpen(false);
                action.handler();
              }}
              sx={{
                width: 40,
                height: 40,
              }}
            />
          ))}
        </SpeedDial>
      </Box>

      {activeDialog === 'task' && (
        <QuickTaskDialog open={true} onClose={handleClose} />
      )}
      {activeDialog === 'idea' && (
        <QuickIdeaDialog open={true} onClose={handleClose} />
      )}
      {activeDialog === 'taskboard' && (
        <QuickTaskboardDialog open={true} onClose={handleClose} />
      )}
      {activeDialog === 'band' && (
        <QuickTaskboardDialog open={true} onClose={handleClose} />
      )}
    </>
  );
};

export default QuickAddButton;