import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  IconButton,
  Typography,
  Box,
  useTheme,
  useMediaQuery
} from '@mui/material';
import {
  Phone,
  Email,
  Close,
  WhatsApp
} from '@mui/icons-material';

interface ContactModalProps {
  open: boolean;
  onClose: () => void;
  contactData: {
    name: string;
    email?: string;
    phone?: string;
    type: 'artist' | 'venue';
  };
}

const ContactModal: React.FC<ContactModalProps> = ({
  open,
  onClose,
  contactData
}) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

  const handleEmailClick = () => {
    if (contactData.email) {
      window.location.href = `mailto:${contactData.email}`;
    }
  };

  const handlePhoneClick = () => {
    if (contactData.phone) {
      window.location.href = `tel:${contactData.phone}`;
    }
  };

  const handleWhatsAppClick = () => {
    if (contactData.phone) {
      // Remove any non-numeric characters from phone number
      const cleanPhone = contactData.phone.replace(/\D/g, '');
      window.open(`https://wa.me/${cleanPhone}`, '_blank');
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      fullScreen={isMobile}
    >
      <DialogTitle>
        <Box display="flex" justifyContent="space-between" alignItems="center">
          <Typography variant="h6">
            Contact {contactData.type === 'artist' ? 'Artist' : 'Venue'}: {contactData.name}
          </Typography>
          <IconButton onClick={onClose} size="small">
            <Close />
          </IconButton>
        </Box>
      </DialogTitle>
      <DialogContent>
        <List>
          {contactData.email && (
            <ListItem button onClick={handleEmailClick}>
              <ListItemIcon>
                <Email color="primary" />
              </ListItemIcon>
              <ListItemText 
                primary="Email"
                secondary={contactData.email}
              />
            </ListItem>
          )}
          
          {contactData.phone && (
            <>
              <ListItem button onClick={handlePhoneClick}>
                <ListItemIcon>
                  <Phone color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="Call"
                  secondary={contactData.phone}
                />
              </ListItem>

              <ListItem button onClick={handleWhatsAppClick}>
                <ListItemIcon>
                  <WhatsApp color="primary" />
                </ListItemIcon>
                <ListItemText 
                  primary="WhatsApp"
                  secondary={contactData.phone}
                />
              </ListItem>
            </>
          )}

          {!contactData.email && !contactData.phone && (
            <Typography color="text.secondary" sx={{ p: 2 }}>
              No contact information available for this {contactData.type}.
            </Typography>
          )}
        </List>
      </DialogContent>
    </Dialog>
  );
};

export default ContactModal;