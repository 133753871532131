import React from 'react';
import { 
  Card, 
  CardContent, 
  Typography, 
  List, 
  ListItem, 
  ListItemText, 
  Alert,
  Box,
  Chip,
  Divider,
  Avatar,
  Paper
} from '@mui/material';
import { 
  CalendarMonth,
  Event,
  Schedule,
  LocationOn,
  Person,
  MusicNote,
  Timer,
  DateRange,
  Groups,
  PendingActions,
  Warning,
  Engineering
} from '@mui/icons-material';
import { Performance, Event as EventType, Venue, BandRecommendation } from '../../types/mongodb';
import { format } from 'date-fns';



interface BandEventInfoProps {
  performance: Performance;
}

const BandEventInfo: React.FC<BandEventInfoProps> = ({ performance }) => {
  const eventCountdown = () => {
    if (!performance.startTime) return 'Date not set';
    
    const eventDate = new Date(performance.startTime);
    const currentDate = new Date();
    
    // Reset both dates to start of day for accurate day counting
    eventDate.setHours(0, 0, 0, 0);
    currentDate.setHours(0, 0, 0, 0);
    
    const daysLeft = Math.floor((eventDate.getTime() - currentDate.getTime()) / (1000 * 60 * 60 * 24));
    
    if (daysLeft < 0) {
      return <Chip 
        icon={<Warning sx={{ color: '#E57373' }} />}
        label="Event has passed"
        sx={{
          bgcolor: 'rgba(229, 115, 115, 0.1)',
          border: '1px solid #E57373',
          borderRadius: '16px',
          px: 1,
          '& .MuiChip-label': {
            color: '#DAE1E7'
          }
        }}
      />;
    }
  
    return (
      <Chip 
        icon={<Schedule sx={{ color: '#3A9188' }} />}
        label={`${daysLeft} days until event`}
        sx={{
          bgcolor: 'rgba(58, 145, 136, 0.1)',
          border: '1px solid #3A9188',
          borderRadius: '16px',
          px: 1,
          '& .MuiChip-label': {
            color: '#DAE1E7'
          }
        }}
      />
    );
  };

  const getArtistName = () => {
    try {
      if (performance.simpleBandName) {
        return performance.simpleBandName;
      }
      
      const band = performance.band as BandRecommendation;
      if (!band) return 'Artist TBA';
      return typeof band === 'string' ? 'Unknown Band' : (band.artistName || 'Artist TBA');
    } catch (error) {
      console.error('Error getting artist name:', error);
      return 'Artist TBA';
    }
  };

  const getVenueName = (venue: string | Venue | undefined) => {
    try {
      if (!venue) return 'Venue TBA';
      if (typeof venue === 'string') return 'Venue details pending';
      return venue.name || 'Venue TBA';
    } catch (error) {
      console.error('Error getting venue name:', error);
      return 'Venue TBA';
    }
  };
  
  const getEventInfo = () => {
    try {
      let strandInfo = null;
      let eventInfo = null;

      // Handle Strand Information
      if (performance.strandId) {
        if (typeof performance.strandId === 'string') {
          strandInfo = (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <CalendarMonth sx={{ mr: 1, color: 'primary.main' }} />
                Strand ID: {performance.strandId}
              </Typography>
            </Box>
          );
        } else {
          strandInfo = (
            <Box sx={{ mb: 2 }}>
              <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                <CalendarMonth sx={{ mr: 1, color: 'primary.main' }} />
                Strand: {performance.strandId.name || 'Name pending'}
              </Typography>
              {performance.strandId.startDate && performance.strandId.endDate && (
                <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
                  <DateRange sx={{ mr: 1, color: 'primary.main' }} />
                  Period: {new Date(performance.strandId.startDate).toLocaleDateString()} - 
                        {new Date(performance.strandId.endDate).toLocaleDateString()}
                </Typography>
              )}
            </Box>
          );
        }
      }

      // Handle Event Information
      const event = performance.event as EventType;
      if (event && typeof event !== 'string') {
        eventInfo = (
          <Box sx={{ mb: 2 }}>
            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <Event sx={{ mr: 1, color: 'primary.main' }} />
              Event: {event.name || 'Name pending'}
            </Typography>
            <Typography variant="subtitle1" sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <LocationOn sx={{ mr: 1, color: 'primary.main' }} />
              Venue: {getVenueName(event.venue)}
            </Typography>
          </Box>
        );
      }

      // Return combined info
      return (
        <>
          {strandInfo}
          {eventInfo}
          <Box sx={{ mt: 2 }}>
            {eventCountdown()}
          </Box>
        </>
      );
    } catch (error) {
      console.error('Error getting event info:', error);
      return <Alert severity="warning">Event information unavailable</Alert>;
    }
  };

  const formatTime = (time: Date | string | undefined) => {
    try {
      if (!time) return 'TBA';
      const date = time instanceof Date ? time : new Date(time);
      if (isNaN(date.getTime())) return 'Invalid date';
      return date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } catch (error) {
      console.error('Error formatting time:', error);
      return 'TBA';
    }
  };

  const renderStaff = () => {
    try {
      if (!performance.staff || !Array.isArray(performance.staff) || performance.staff.length === 0) {
        return (
          <Box sx={{ 
            display: 'flex', 
            alignItems: 'center', 
            color: '#A4B0BE',
            gap: 1 
          }}>
            <Groups sx={{ color: '#3A9188' }} />
            <Typography>No staff assigned yet</Typography>
          </Box>
        );
      }
  
      return (
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
          {performance.staff.map((staffAssignment: any) => {
            try {
              const staffData = {
                _id: staffAssignment.staffId?._id || staffAssignment.staffId,
                name: staffAssignment.staffId?.name || 'Name pending',
                role: staffAssignment.assignedRole || 'Role pending'
              };
  
              return (
                <Box 
                  key={staffData._id.toString()}
                  sx={{ 
                    display: 'flex', 
                    alignItems: 'center', 
                    gap: 2,
                    p: 1.5,
                    bgcolor: 'rgba(52, 73, 94, 0.8)',
                    borderRadius: 1,
                    border: '1px solid rgba(58, 145, 136, 0.1)'
                  }}
                >
                  <Avatar sx={{ 
                    bgcolor: 'rgba(58, 145, 136, 0.2)', 
                    border: '1px solid #3A9188' 
                  }}>
                    <Person sx={{ color: '#3A9188' }} />
                  </Avatar>
                  <Box>
                    <Typography sx={{ color: '#fff', fontWeight: 500 }}>
                      {staffData.name}
                    </Typography>
                    <Typography sx={{ color: '#A4B0BE' }}>
                      {staffData.role}
                    </Typography>
                  </Box>
                </Box>
              );
            } catch (error) {
              console.error('Error rendering staff member:', error);
              return null;
            }
          }).filter(Boolean)}
        </Box>
      );
    } catch (error) {
      console.error('Error rendering staff list:', error);
      return <Alert severity="warning">Unable to display staff information</Alert>;
    }
  };

  if (!performance) {
    return (
      <Card>
        <CardContent>
          <Alert severity="error">No performance data available</Alert>
        </CardContent>
      </Card>
    );
  }

  return (
    <Card sx={{ 
      mb: 4,
      backgroundColor: '#34495E',
      border: '1px solid #3A9188',
      boxShadow: '0 4px 12px rgba(0, 0, 0, 0.1)',
    }}>
      <CardContent>
        {/* Header Section with Artist Name and Date */}
        <Box sx={{ 
          display: 'flex',
          flexDirection: 'column',
          gap: 1,
          mb: 3,
          position: 'relative'
        }}>
          <Box sx={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start'
          }}>
            <Typography variant="h2" sx={{ 
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              gap: 2,
              fontWeight: 700,
            }}>
              {getArtistName()}
            </Typography>
            
            {eventCountdown()} 
            </Box>
          
          <Typography 
            variant="h3" 
            sx={{ 
              color: '#E57373',
              fontWeight: 500,
              fontSize: '1.75rem'
            }}
          >
            {format(new Date(performance.startTime), 'do MMMM yyyy')}
          </Typography>
        </Box>

        {/* Strand and Period Info */}
        <Paper sx={{ 
          bgcolor: 'rgba(52, 73, 94, 0.6)',
          p: 2,
          mb: 3,
          border: '1px solid rgba(58, 145, 136, 0.2)',
          borderRadius: 2
        }}>
          <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}>
            <Typography sx={{ 
              display: 'flex', 
              alignItems: 'center',
              gap: 1,
              color: '#DAE1E7'
            }}>
              <CalendarMonth sx={{ color: '#3A9188' }} />
              Strand: Cooler Domestic
            </Typography>
            <Typography sx={{ 
              color: '#A4B0BE',
              pl: 4
            }}>
              Period: 01/01/2025 -31/12/2025
            </Typography>
          </Box>
        </Paper>

        {/* Time Section */}
        <Box sx={{ 
          display: 'grid',
          gridTemplateColumns: 'repeat(2, 1fr)',
          gap: 2,
          mb: 3
        }}>
          <Paper sx={{ 
            bgcolor: 'rgba(52, 73, 94, 0.6)',
            p: 2,
            border: '1px solid rgba(58, 145, 136, 0.2)',
            borderRadius: 2
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Timer sx={{ color: '#3A9188' }} />
              <Box>
                <Typography variant="overline" sx={{ color: '#A4B0BE' }}>
                  START TIME
                </Typography>
                <Typography variant="h5" sx={{ color: '#fff' }}>
                  19:30
                </Typography>
              </Box>
            </Box>
          </Paper>

          <Paper sx={{ 
            bgcolor: 'rgba(52, 73, 94, 0.6)',
            p: 2,
            border: '1px solid rgba(229, 115, 115, 0.2)',
            borderRadius: 2
          }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Timer sx={{ color: '#E57373' }} />
              <Box>
                <Typography variant="overline" sx={{ color: '#A4B0BE' }}>
                  END TIME
                </Typography>
                <Typography variant="h5" sx={{ color: '#fff' }}>
                  23:00
                </Typography>
              </Box>
            </Box>
          </Paper>
        </Box>

        {/* Status */}
        <Box sx={{ mb: 3, display: 'flex', justifyContent: 'center' }}>
          <Chip
            label="confirmed"
            sx={{
              bgcolor: 'rgba(58, 145, 136, 0.2)',
              color: '#DAE1E7',
              border: '1px solid #3A9188',
              px: 3,
              py: 2,
              '& .MuiChip-label': {
                fontSize: '1rem',
                fontWeight: 500
              }
            }}
          />
        </Box>

        {/* Staff Section */}
        <Paper sx={{ 
          bgcolor: 'rgba(52, 73, 94, 0.6)',
          p: 2,
          border: '1px solid rgba(58, 145, 136, 0.2)',
          borderRadius: 2
        }}>
          <Typography 
            variant="h6" 
            sx={{ 
              color: '#3A9188',
              mb: 2,
              fontWeight: 600,
              display: 'flex',
              alignItems: 'center',
              gap: 1
            }}
          >
            <Engineering />
            Assigned Staff
          </Typography>
          
          {renderStaff()}
        </Paper>
      </CardContent>
    </Card>
  );
};

export default BandEventInfo;