import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import {
  Box,
  Typography,
  CircularProgress,
  Alert,
  Paper,
  Container,
  Breadcrumbs,
  Link,
  IconButton,
  Grid,
  Card,
  CardContent,
  Chip,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  Divider
} from '@mui/material';
import { ArrowLeft, Calendar, User, FileText } from 'lucide-react';
import axiosInstance from '../../utils/axiosConfig';
import { Personnel } from '../../types/Personnel';
import { Performance } from '../../types/mongodb';
import UpcomingPerformances from '../../components/dashboard/UpcomingPerformances';

const PersonnelDashboard = () => {
  const navigate = useNavigate();
  const { user, isAuthenticated } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState<{
    personnel: Personnel | null;
    nextPerformances: Performance[];
  }>({
    personnel: null,
    nextPerformances: []
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!user || !isAuthenticated) {
        setError('User not authenticated');
        setLoading(false);
        return;
      }

      try {
        const [personnelRes, performancesRes] = await Promise.all([
          axiosInstance.get(`/api/personnel/by-user/${user._id}`),
          axiosInstance.get('/api/performances/staff/${user._id}', {
            params: { limit: 5 }
          })
        ]);

        setData({
          personnel: personnelRes.data,
          nextPerformances: performancesRes.data
        });
        setLoading(false);
      } catch (err) {
        console.error('Error fetching data:', err);
        setError('Failed to load dashboard data');
        setLoading(false);
      }
    };

    fetchData();
  }, [user, isAuthenticated]);

  const handleBack = () => {
    navigate(-1);
  };

  if (loading) {
    return (
      <Box sx={{ 
        display: 'flex', 
        justifyContent: 'center', 
        alignItems: 'center', 
        minHeight: 'calc(100vh - 64px)' 
      }}>
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box sx={{ p: 4 }}>
        <Alert severity="error">{error}</Alert>
      </Box>
    );
  }

  const { personnel, nextPerformances } = data;

  if (!personnel) {
    return (
      <Box sx={{ p: 4 }}>
        <Alert severity="warning">No personnel data found</Alert>
      </Box>
    );
  }

  return (
    <Box sx={{ minHeight: '100vh', bgcolor: 'background.default' }}>

      {/* Main Content */}
      <Container maxWidth="lg" sx={{ py: 4 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: 3 }}>
          {/* Profile Information */}
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <User size={24} />
              <Typography variant="h5">Your Profile Information</Typography>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12} md={6}>
                <Typography><strong>Name:</strong> {personnel.name}</Typography>
                <Typography><strong>Email:</strong> {personnel.email}</Typography>
                <Typography><strong>Phone:</strong> {personnel.phone}</Typography>
              </Grid>
              <Grid item xs={12} md={6}>
                <Typography><strong>Experience:</strong> {personnel.experience} years</Typography>
                <Typography><strong>Skill Level:</strong> {personnel.skillLevel}</Typography>
                <Box sx={{ mt: 1 }}>
                  {personnel.roles.map((role, index) => (
                    <Chip 
                      key={index}
                      label={role}
                      color="primary"
                      variant="outlined"
                      size="small"
                      sx={{ mr: 1, mb: 1 }}
                    />
                  ))}
                </Box>
              </Grid>
            </Grid>
          </Paper>

          {/* Upcoming Assignments */}
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <Calendar size={24} />
              <Typography variant="h5"> Your Upcoming Assignments</Typography>
            </Box>
            <UpcomingPerformances 
              performances={nextPerformances}
              personnelId={personnel._id}
            />
          </Paper>

          {/* Documents Section */}
          <Paper sx={{ p: 3 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2, mb: 2 }}>
              <FileText size={24} />
              <Typography variant="h5">Your Documents</Typography>
            </Box>
            <Box sx={{ 
              display: 'flex', 
              flexDirection: 'column', 
              gap: 2 
            }}>
              {/* Add document components here */}
              <Typography color="text.secondary">
                Document management functionality coming soon...
              </Typography>
            </Box>
          </Paper>
        </Box>
      </Container>
    </Box>
  );
};

export default PersonnelDashboard;