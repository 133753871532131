import React, { useState, useEffect } from 'react';
import {
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  useTheme
} from '@mui/material';
import { CalendarCheck } from 'lucide-react';
import { BandRecommendation, Performance } from '../../types/mongodb';
import axiosInstance from '../../utils/axiosConfig';
import PerformanceCard from './PerformanceCard';

interface PerformanceStaff {
  _id: string;
  name: string;
  role: string; 
  staffId: string;
  assignedRole: string;
  fee: number;
}

interface BandDetails {
  _id: string;
  artistName: string;
  mainImage?: string;
}

interface UpcomingPerformancesProps {
  performances: Array<Performance>;
  personnelId: string;
}

const UpcomingPerformances: React.FC<UpcomingPerformancesProps> = ({ 
  performances,
  personnelId
}) => {
  const theme = useTheme();
  const [bandDetails, setBandDetails] = useState<Record<string, BandDetails>>({});

  useEffect(() => {
    const fetchBandDetails = async () => {
      const bandIds = performances
        .filter(perf => typeof perf.band === 'string')
        .map(perf => perf.band as string);

      for (const bandId of bandIds) {
        try {
          const response = await axiosInstance.get(`/api/bandRecommendations/${bandId}`);
          setBandDetails(prev => ({
            ...prev,
            [bandId]: {
              _id: response.data._id,
              artistName: response.data.artistName,
              mainImage: response.data.mainImage
            }
          }));
        } catch (error) {
          console.error(`Error fetching band ID ${bandId}:`, error);
        }
      }
    };

    fetchBandDetails();
  }, [performances]);

  const getBandName = (performance: Performance): string => {
    if (performance.simpleBandName) {
      return performance.simpleBandName;
    }
    
    if (typeof performance.band === 'string') {
      const details = bandDetails[performance.band];
      return details?.artistName || 'Loading...';
    }
    
    if (typeof performance.band === 'object' && performance.band && 'artistName' in performance.band) {
      return performance.band.artistName;
    }
    
    return 'Untitled Performance';
  };

  const getBandImage = (performance: Performance): string | undefined => {
    if (typeof performance.band === 'string') {
      const details = bandDetails[performance.band];
      return details?.mainImage;
    }
    
    if (typeof performance.band === 'object' && 
        performance.band && 
        'mainImage' in performance.band) {
      return performance.band.mainImage;
    }
    
    return undefined;
  };

  if (!performances.length) return null;

  return (
    <Accordion 
      defaultExpanded 
      sx={{ 
        mb: 2,
        backgroundColor: theme.palette.background.paper
      }}
    >
      <AccordionSummary 
        expandIcon={<CalendarCheck />}
        sx={{
          borderBottom: 1,
          borderColor: 'divider'
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          <CalendarCheck color={theme.palette.primary.main} />
          <Typography>Your Upcoming Gigs</Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        {performances.map((perf) => {
          const staffMember = perf.staff?.find(
            (member): member is PerformanceStaff => 
              typeof member === 'object' &&
              member !== null &&
              'staffId' in member &&
              'assignedRole' in member
          );

          return (
            <PerformanceCard
              key={perf._id}
              performance={perf}
              bandName={getBandName(perf)}
              bandImage={getBandImage(perf)}
              staffMember={staffMember}
            />
          );
        })}
      </AccordionDetails>
    </Accordion>
  );
};

export default UpcomingPerformances;