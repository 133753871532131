import React from 'react';
import { Box, Typography, Grid } from '@mui/material';
import { Venue } from '../../../../types/mongodb';

interface RevenueProjectionsProps {
  venue: Venue;
  costs: number;
  contingency: number;
}

export const RevenueProjections: React.FC<RevenueProjectionsProps> = ({
  venue,
  costs,
  contingency
}) => {
  // Calculate base ticket price needed at 60% capacity
  const baseTicketPrice = () => {
    const targetAttendance = Math.floor(venue.capacity * 0.6);
    const requiredRevenue = costs + contingency;
    const rawPrice = requiredRevenue / targetAttendance;
    // Round up to nearest 5
    return Math.ceil(rawPrice / 5) * 5;
  };

  const ticketPrice = baseTicketPrice();

  // Calculate each scenario with fixed ticket price
  const scenarios = {
    worst: {
      attendance: Math.floor(venue.capacity * 0.3),
      revenue: Math.floor(venue.capacity * 0.3) * ticketPrice,
    },
    expected: {
      attendance: Math.floor(venue.capacity * 0.6),
      revenue: Math.floor(venue.capacity * 0.6) * ticketPrice,
    },
    best: {
      attendance: Math.floor(venue.capacity * 0.9),
      revenue: Math.floor(venue.capacity * 0.9) * ticketPrice,
    }
  };

  // Calculate break-even point
  const totalCosts = costs + contingency;
  const breakEvenTickets = Math.ceil(totalCosts / ticketPrice);
  const breakEvenPercentage = Math.round((breakEvenTickets / venue.capacity) * 100);

  return (
    <Box sx={{ 
      bgcolor: 'background.paper', 
      p: 3, 
      borderRadius: 1,
      mt: 3 
    }}>
      <Typography variant="h6" gutterBottom>Revenue Projections</Typography>
      
      <Box sx={{ mb: 3 }}>
        <Typography variant="subtitle1" gutterBottom>
          Venue Capacity: {venue.capacity} people
        </Typography>
        <Typography variant="subtitle1" gutterBottom color="primary">
          Ticket Price: €{ticketPrice.toFixed(2)}
        </Typography>
        <Typography variant="subtitle1" gutterBottom color="text.secondary">
          Total Costs: €{totalCosts.toFixed(2)}
        </Typography>
      </Box>
      
      <Grid container spacing={2}>
        {Object.entries(scenarios).map(([scenario, data]) => {
          const profit = data.revenue - totalCosts;
          return (
            <Grid item xs={12} md={4} key={scenario}>
              <Box sx={{ 
                p: 2, 
                border: 1, 
                borderColor: 'divider',
                borderRadius: 1
              }}>
                <Typography variant="subtitle1" gutterBottom sx={{ textTransform: 'capitalize' }}>
                  {scenario} Case
                </Typography>
                <Typography>Attendance: {data.attendance}</Typography>
                <Typography>Revenue: €{data.revenue.toFixed(2)}</Typography>
                <Typography sx={{ 
                  color: profit >= 0 ? 'success.main' : 'error.main',
                  fontWeight: 'bold'
                }}>
                  {profit >= 0 ? 'Profit' : 'Subvention Required'}: €{Math.abs(profit).toFixed(2)}
                </Typography>
              </Box>
            </Grid>
          );
        })}
      </Grid>

      <Box sx={{ mt: 3, p: 2, bgcolor: 'action.hover', borderRadius: 1 }}>
        <Typography variant="subtitle1" gutterBottom>
          Break-even Analysis
        </Typography>
        <Typography>
          Need to sell {breakEvenTickets} tickets ({breakEvenPercentage}% capacity)
        </Typography>
      </Box>
    </Box>
  );
};

export default RevenueProjections;