import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Typography,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  Chip,
  CircularProgress,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  IconButton,
  Button
} from '@mui/material';
import { 
  CalendarCheck,
  Clock,
  Lightbulb,
  Edit,
  Trash2
} from 'lucide-react';
import { format, isAfter } from 'date-fns';
import { Performance, Staff } from '../../types/mongodb';
import axiosInstance from '../../utils/axiosConfig';
import { useAuth } from '../../contexts/AuthContext';
import TaskList from './TaskList';
import TaskboardList from './TaskboardList';
import UpcomingPerformances from './UpcomingPerformances';

interface Task {
  _id: string;
  text: string;
  completed: boolean;
  urgent: boolean;
  created: string;
  assignedTo: string;
  createdBy: string;
  dueDate?: string;
  strandId?: string;
  createdAt: string;
  updatedAt: string;
}

interface TaskboardSubtask {
  _id: string;
  title: string;
  completed: boolean;
  assignedTo: string;
  dueDate: string;
  boardId?: string;
  boardTitle?: string;
}

interface Taskboard {
  _id: string;
  completed?: boolean;
  title: string;
  description: string;
  status: string;
  priority: 'high' | 'medium' | 'low';
  progress: number;
  category: string;
  dueDate: string;
  subtasks: TaskboardSubtask[];
  assignedTo: string;
}

interface Idea {
  _id: string;
  title: string;
  sourceType: string;
  sourceUrl?: string;
  notes?: string;
  status: string;
  priority: 'high' | 'medium' | 'low';
  createdBy: string;
  createdAt: string;
  updatedAt: string;
}

// Define a type that includes both string and Staff object for staff members
type PerformanceStaff = {
  _id: string;
  name?: string;
  role?: string;
  fee?: number;
} | string;

// Extend the Performance type to ensure compatibility
interface PerformanceWithStaff extends Omit<Performance, 'staff'> {
  staff: PerformanceStaff[];
}

const TasksOverview: React.FC<{ userId: string }> = ({ userId }) => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [data, setData] = useState({
    nextPerformances: [] as Performance[],
    tasks: [] as Task[],
    taskboards: [] as Taskboard[],
    ideas: [] as Idea[]
  });

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const [performancesRes, tasksRes, taskboardsRes, ideasRes] = await Promise.all([
          axiosInstance.get<Performance[]>('/api/performances/staff/${userId}', {
            params: { limit: 3 }
          }),
          axiosInstance.get<Task[]>('/api/tasks', {
            params: {
              assignedTo: userId,
              completed: false
            }
          }),
          axiosInstance.get<Taskboard[]>('/api/taskboards', {
            params: {
              $or: [
                { assignedTo: userId },
                { 'subtasks.assignedTo': userId }
              ],
              status: { $ne: 'done' }
            }
          }),
          axiosInstance.get<Idea[]>('/api/ideas', {
            params: {
              createdBy: userId,
              status: ['new', 'in_progress']
            }
          })
        ]);

        setData({
          nextPerformances: performancesRes.data
            .filter(perf => isAfter(new Date(perf.startTime), new Date()))
            .slice(0, 3),
          tasks: tasksRes.data,
          taskboards: taskboardsRes.data,
          ideas: ideasRes.data
        });

        setLoading(false);
      } catch (err) {
        console.error('Error fetching user data:', err);
        setError('Unable to load dashboard data');
        setLoading(false);
      }
    };

    if (userId) {
      fetchUserData();
    }
  }, [userId, user]);

  const handleDeleteIdea = async (ideaId: string) => {
    try {
      await axiosInstance.delete(`/api/ideas/${ideaId}`);
      setData(prev => ({
        ...prev,
        ideas: prev.ideas.filter(idea => idea._id !== ideaId)
      }));
    } catch (error) {
      console.error('Error deleting idea:', error);
    }
  };

  const handleEditIdea = (ideaId: string) => {
    navigate(`/admin/ideas?edit=${ideaId}`);
  };

  if (loading) return <CircularProgress />;
  if (error) return <Alert severity="error">{error}</Alert>;

  const { nextPerformances, tasks, taskboards, ideas } = data;

  const normalizedPerformances = nextPerformances.map(perf => ({
    ...perf,
    staff: perf.staff?.filter((member): member is Staff => typeof member !== 'string') || [],
    band: typeof perf.band === 'string' ? perf.band : perf.band || ''  }));
  

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1 }}> 
      {/* Adds vertical spacing between components */}
  
      <Typography variant="h4" gutterBottom>
        Your Overview
      </Typography>
  
      <Box sx={{ mb: 0.25 }}>
        <UpcomingPerformances 
          performances={normalizedPerformances}
          personnelId={userId}
        />
      </Box>
  
      <Box sx={{ mb: 0.5 }}>
        <TaskList userId={userId} />
      </Box>
  
      <Box sx={{ mb: 0.5 }}>
        <TaskboardList userId={userId} />
      </Box>
  
      <Accordion sx={{ mb: 0.5, p: 0.5 }}>
        <AccordionSummary expandIcon={<Clock />}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Lightbulb />
            <Typography>Your Ideas</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <List dense>
            {ideas.map((idea) => (
              <ListItem key={idea._id} sx={{ mb: 0.5, py: 0.5 }}>
                <ListItemIcon>
                  <Lightbulb className={
                    idea.priority === 'high' ? 'text-purple-500' :
                    idea.priority === 'medium' ? 'text-blue-500' :
                    'text-gray-500'
                  } />
                </ListItemIcon>
                <ListItemText
                  primary={idea.title}
                  secondary={
                    <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                      <Chip size="small" label={idea.status} />
                      <Typography variant="caption">
                        Created: {format(new Date(idea.createdAt), 'PP')}
                      </Typography>
                    </Box>
                  }
                />
                <ListItemSecondaryAction>
                  <IconButton edge="end" onClick={() => handleEditIdea(idea._id)}>
                    <Edit size={16} />
                  </IconButton>
                  <IconButton edge="end" onClick={() => handleDeleteIdea(idea._id)}>
                    <Trash2 size={16} />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
            <Button
              fullWidth
              variant="outlined"
              startIcon={<Lightbulb />}
              onClick={() => navigate('/admin/ideas')}
              sx={{ mt: 2 }}
            >
              Add New Idea
            </Button>
          </List>
        </AccordionDetails>
      </Accordion>
    </Box>
  );
};

export default TasksOverview;