import React from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Card,
  CardContent,
  Typography,
  Box,
  Chip
} from '@mui/material';
import { format, differenceInDays } from 'date-fns';
import { Performance } from '../../types/mongodb';

interface PerformanceStaff {
  _id: string;
  name: string;
  role: string; 
  staffId: string;
  assignedRole: string;
  fee: number;
}

interface PerformanceCardProps {
  performance: Performance;
  bandName: string;
  bandImage?: string;
  staffMember?: PerformanceStaff;
}

const PerformanceCard: React.FC<PerformanceCardProps> = ({ 
  performance, 
  bandName, 
  bandImage, 
  staffMember 
}) => {
  const navigate = useNavigate();
  const daysUntil = differenceInDays(new Date(performance.startTime), new Date());

  return (
    <Card 
      onClick={() => navigate(`/admin/performances/${performance._id}`)}
      sx={{ 
        mb: 2,
        position: 'relative',
        minHeight: 200,
        overflow: 'hidden',
        cursor: 'pointer',
        transition: 'transform 0.2s ease-in-out',
        '&:hover': {
          transform: 'scale(1.02)',
        },
        '&::before': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          backgroundImage: bandImage ? `url(${bandImage})` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          zIndex: 0
        },
        '&::after': {
          content: '""',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
          background: `linear-gradient(to bottom, 
            rgba(0,0,0,0.2) 0%, 
            rgba(0,0,0,0.7) 50%, 
            rgba(0,0,0,0.9) 100%)`,
          zIndex: 1
        }
      }}
    >
      <CardContent 
        sx={{ 
          position: 'relative',
          zIndex: 2,
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'flex-end',
          color: 'white'
        }}
      >
        <Typography 
          variant="h5" 
          sx={{ 
            mb: 1,
            textShadow: '2px 2px 4px rgba(0,0,0,0.5)'
          }}
        >
          {bandName}
        </Typography>
        <Typography 
          variant="body1"
          sx={{ 
            mb: 2,
            textShadow: '1px 1px 2px rgba(0,0,0,0.5)'
          }}
        >
          {format(new Date(performance.startTime), 'PPP p')}
        </Typography>
        
        <Box sx={{ 
          display: 'flex', 
          gap: 1, 
          flexWrap: 'wrap'
        }}>
          <Chip
            label={`${daysUntil} days until show`}
            color="primary"
            size="small"
            sx={{ 
              backgroundColor: 'rgba(255,255,255,0.15)',
              backdropFilter: 'blur(10px)',
              color: 'white',
              '& .MuiChip-label': {
                textShadow: '1px 1px 2px rgba(0,0,0,0.5)'
              }
            }}
          />
          {staffMember && (
            <Chip
              label={staffMember.assignedRole}
              color="secondary"
              size="small"
              sx={{ 
                backgroundColor: 'rgba(255,255,255,0.15)',
                backdropFilter: 'blur(10px)',
                color: 'white',
                '& .MuiChip-label': {
                  textShadow: '1px 1px 2px rgba(0,0,0,0.5)'
                }
              }}
            />
          )}
        </Box>
      </CardContent>
    </Card>
  );
};

export default PerformanceCard;