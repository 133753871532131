import React, { useState, useEffect } from 'react';
import {
  Box,
  Typography,
  Paper,
  TextField,
  Button,
  IconButton,
  Grid,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel
} from '@mui/material';
import { Plus, Minus, Download, Calendar } from 'lucide-react';
import { format, addDays, parse } from 'date-fns';
import { 
  EVENT_TYPES,
  ScheduleItem,
  getCoolerTemplate,
  getDefaultVenueTemplate,
  getInternationalTemplate 
} from '../../utils/scheduleTemplates';
import { exportScheduleToPDF } from '../../utils/pdfExport';
import { exportScheduleToWord } from '../../utils/wordExport';
import { Microsoft, PictureAsPdf } from '@mui/icons-material';


interface TourDay {
  date: Date;
  items: ScheduleItem[];
}

interface TourSchedulerProps {
  performanceData?: {
    bandName: string;
    contactName: string;
    contactEmail: string;
    contactPhone: string;
    venue: {
      name: string;
      location: string;
    };
    timing: {
      date: Date | null;
      startTime: Date | null;
      endTime: Date | null;
      soundcheckTime: Date | null;
    };
  };
}


const TourScheduler: React.FC<TourSchedulerProps> = ({ performanceData }) => {
  const [schedule, setSchedule] = useState<TourDay[]>([]);
  const [isInternational, setIsInternational] = useState(false);
  const [variables, setVariables] = useState({
    ARTIST: performanceData?.bandName || '',
    VENUE: performanceData?.venue?.name || '',
    VENUE_ADDRESS: performanceData?.venue?.location || '',
    DATE: performanceData?.timing?.date ? format(performanceData.timing.date, 'dd-MM-yyyy') : '',
    LOCATIONA: '',
    LOCATIONB: '',
    AIRLINE: '',
    FLIGHT_NO: '',
    BOOKING_REF: '',
    HOTEL: '',
    CONTACT_NAME: performanceData?.contactName || '',
    CONTACT_EMAIL: performanceData?.contactEmail || '',
    CONTACT_PHONE: performanceData?.contactPhone || ''
  });

  useEffect(() => {
    if (performanceData?.timing?.date) {
      const template = isInternational ? 
        getInternationalTemplate(variables) : 
        performanceData.venue.name === 'The Cooler' ?
          getCoolerTemplate(variables) :
          getDefaultVenueTemplate(variables);

      setSchedule([{
        date: new Date(performanceData.timing.date),
        items: template
      }]);
    }
  }, [performanceData, isInternational, variables]);

  const addDay = () => {
    const lastDay = schedule[schedule.length - 1];
    const newDate = lastDay ? addDays(lastDay.date, 1) : new Date();
    
    const template = isInternational ? 
      getInternationalTemplate(variables) : 
      performanceData?.venue.name === 'The Cooler' ?
        getCoolerTemplate(variables) :
        getDefaultVenueTemplate(variables);
    
    setSchedule([...schedule, {
      date: newDate,
      items: template
    }]);
  };

  const removeDay = (index: number) => {
    setSchedule(schedule.filter((_, i) => i !== index));
  };

  const addScheduleItem = (dayIndex: number) => {
    const newItem: ScheduleItem = {
      id: Math.random().toString(36).substr(2, 9),
      time: '',
      type: 'OTHER',
      description: ''
    };

    const newSchedule = [...schedule];
    newSchedule[dayIndex].items.push(newItem);
    setSchedule(newSchedule);
  };

  const updateScheduleItem = (dayIndex: number, itemIndex: number, field: keyof ScheduleItem, value: string) => {
    const newSchedule = [...schedule];
    const item = newSchedule[dayIndex].items[itemIndex];
    
    if (field === 'time') {
      item.time = value;
    } else if (field === 'type') {
      item.type = value as keyof typeof EVENT_TYPES;
    } else if (field === 'description') {
      item.description = value;
    }
    
    setSchedule(newSchedule);
  };

  const exportSchedule = () => {
    
    exportScheduleToPDF(schedule, {
      title: `Schedule - ${variables.ARTIST}`,
      artist: variables.ARTIST,
      venue: variables.VENUE,
      venueAddress: variables.VENUE_ADDRESS,
      contactInfo: {
        name: variables.CONTACT_NAME,
        email: variables.CONTACT_EMAIL,
        phone: variables.CONTACT_PHONE
      },
      logos: {
        primary: `https://storage.googleapis.com/showrunnr-bucket/logos/imc-logo.png`,
        secondary: `https://storage.googleapis.com/showrunnr-bucket/logos/venue-logo.png`  // If applicable
      }
    });
  };


  return (
<Box sx={{ p: 4 }}>
  <Box sx={{ 
    mb: 4, 
    display: 'flex', 
    justifyContent: 'space-between', 
    alignItems: 'center' 
  }}>
    <Typography variant="h4">Schedule</Typography>
    
    <Box sx={{ display: 'flex', gap: 2 }}>
      <Button 
        variant="contained"
        color="success"
        startIcon={<PictureAsPdf />}
        onClick={exportSchedule}
      >
        Export PDF
      </Button>
      <Button 
  variant="contained"
  color="info"
  startIcon={<Microsoft />}
  onClick={async () => {
    // Get current state snapshot
    const currentSchedule = [...schedule];  // Create a fresh copy
    
    try {
      const url = await exportScheduleToWord(currentSchedule, {
        artist: variables.ARTIST,
        venue: variables.VENUE,
        venueAddress: variables.VENUE_ADDRESS,
        contactInfo: {
          name: variables.CONTACT_NAME,
          email: variables.CONTACT_EMAIL,
          phone: variables.CONTACT_PHONE,
        },
      });
      
      // Log current state for verification
      console.log('Exporting current schedule state:', currentSchedule);
      
      window.open(url, '_blank');
    } catch (error) {
      console.error('Error exporting Word document:', error);
      // You might want to add error handling UI here
    }
  }}
>
  Export Word
</Button>
    </Box>
  </Box>

      <Paper sx={{ p: 3, mb: 4 }}>
        <Typography variant="h6" gutterBottom>Schedule Configuration</Typography>
        <FormControlLabel
          control={
            <Switch
              checked={isInternational}
              onChange={(e) => setIsInternational(e.target.checked)}
            />
          }
          label="International Artist (Include Travel)"
          sx={{ mb: 2 }}
        />

        <Typography variant="h6" gutterBottom>Variables</Typography>
        <Grid container spacing={2}>
          {Object.entries(variables).map(([key, value]) => (
            isInternational || !['AIRLINE', 'FLIGHT_NO', 'BOOKING_REF', 'LOCATIONA', 'LOCATIONB'].includes(key) ? (
              <Grid item xs={12} sm={6} md={4} key={key}>
                <TextField
                  fullWidth
                  label={key.replace(/_/g, ' ')}
                  value={value}
                  onChange={(e) => setVariables({ ...variables, [key]: e.target.value })}
                />
              </Grid>
            ) : null
          ))}
        </Grid>
      </Paper>

      {schedule.map((day, dayIndex) => (
        <Paper key={dayIndex} sx={{ p: 3, mb: 3 }}>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              <Calendar size={24} />
              <TextField
                type="date"
                value={format(day.date, 'yyyy-MM-dd')}
                onChange={(e) => {
                  const newSchedule = [...schedule];
                  newSchedule[dayIndex].date = parse(e.target.value, 'yyyy-MM-dd', new Date());
                  setSchedule(newSchedule);
                }}
              />
            </Box>
            <IconButton onClick={() => removeDay(dayIndex)} color="error">
              <Minus />
            </IconButton>
          </Box>

          {day.items.map((item, itemIndex) => (
  <Box key={item.id} sx={{ mb: 2 }}>
    <Grid container spacing={2} alignItems="center">
      <Grid item xs={2}>
        <TextField
          fullWidth
          label="Time"
          type="time"
          value={item.time}
          onChange={(e) => updateScheduleItem(dayIndex, itemIndex, 'time', e.target.value)}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={2}>
        <FormControl fullWidth>
          <InputLabel>Type</InputLabel>
          <Select
            value={item.type}
            onChange={(e) => updateScheduleItem(dayIndex, itemIndex, 'type', e.target.value)}
            label="Type"
          >
            {Object.entries(EVENT_TYPES).map(([key, value]) => (
              <MenuItem key={key} value={key}>{value}</MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={7}>
        <TextField
          fullWidth
          label="Description"
          value={item.description}
          onChange={(e) => updateScheduleItem(dayIndex, itemIndex, 'description', e.target.value)}
          multiline
          InputProps={{
            sx: { fontSize: '1rem' }
          }}
          FormHelperTextProps={{
            sx: { 
              fontSize: '0.75rem', 
              color: 'text.secondary',
              fontStyle: 'italic'
            }
          }}
          helperText={item.template}
        />
      </Grid>
      <Grid item xs={1}>
        <Box sx={{ display: 'flex', gap: 1 }}>
          <IconButton 
            size="small" 
            color="error"
            onClick={() => {
              const newSchedule = [...schedule];
              newSchedule[dayIndex].items.splice(itemIndex, 1);
              setSchedule(newSchedule);
            }}
          >
            <Minus />
          </IconButton>
          <IconButton 
            size="small" 
            onClick={() => {
              const newSchedule = [...schedule];
              const newItem: ScheduleItem = {
                id: Math.random().toString(36).substr(2, 9),
                time: '',
                type: 'OTHER',
                description: ''
              };
              newSchedule[dayIndex].items.splice(itemIndex + 1, 0, newItem);
              setSchedule(newSchedule);
            }}
          >
            <Plus />
          </IconButton>
        </Box>
      </Grid>
    </Grid>
  </Box>
))}
          <Button
            startIcon={<Plus />}
            onClick={() => addScheduleItem(dayIndex)}
            sx={{ mt: 2 }}
          >
            Add Schedule Item
          </Button>
        </Paper>
      ))}

      <Button
        variant="outlined"
        startIcon={<Plus />}
        onClick={addDay}
        fullWidth
        sx={{ mt: 2 }}
      >
        Add Day
      </Button>
    </Box>
  );
};

export default TourScheduler;