import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  IconButton,
  Checkbox,
  Typography,
  Chip,
  CircularProgress,
  Alert,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Badge,
  Menu,
  MenuItem,
  Button,
  Dialog,
  Tooltip
} from '@mui/material';
import { Clock, ClipboardList, ExternalLink, MoreVertical, Calendar, Plus, History } from 'lucide-react';
import { format, addDays } from 'date-fns';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import axiosInstance from '../../utils/axiosConfig';
import QuickTasks from '../QuickTasks';

// Create a client
const queryClient = new QueryClient();

interface TaskItem {
  _id: string;
  text: string;
  completed: boolean;
  urgent: boolean;
  created: string;
  assignedTo: string;
  createdBy: string;
  dueDate?: string;
  createdAt: string;
  updatedAt: string;
  deferCount: number;
}

interface TaskListState {
  tasks: TaskItem[];
  loading: boolean;
  error: string | null;
  personnelId: string | null;
}

interface TaskListProps {
  userId: string;
}

const TaskDialog: React.FC<{ open: boolean; onClose: () => void }> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
    >
      <QueryClientProvider client={queryClient}>
        <QuickTasks />
      </QueryClientProvider>
    </Dialog>
  );
};

const TaskList: React.FC<TaskListProps> = ({ userId }) => {
  const navigate = useNavigate();
  const [state, setState] = useState<TaskListState>({
    tasks: [],
    loading: true,
    error: null,
    personnelId: null
  });
  
  const [menuAnchor, setMenuAnchor] = useState<null | HTMLElement>(null);
  const [selectedTask, setSelectedTask] = useState<string | null>(null);
  const [addTaskOpen, setAddTaskOpen] = useState(false);

  const handleTaskToggle = async (taskId: string) => {
    if (!state.personnelId) return;

    try {
      const task = state.tasks.find(t => t._id === taskId);
      if (!task) return;

      await axiosInstance.patch(`/api/tasks/${taskId}/toggle`, {
        completed: !task.completed
      });
      
      setState(prev => ({
        ...prev,
        tasks: prev.tasks.map(t => 
          t._id === taskId ? { ...t, completed: !t.completed } : t
        )
      }));

      fetchTasks();
    } catch (error) {
      console.error('Error toggling task:', error);
      setState(prev => ({
        ...prev,
        error: 'Failed to update task'
      }));
    }
  };

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>, taskId: string) => {
    event.stopPropagation();
    setMenuAnchor(event.currentTarget);
    setSelectedTask(taskId);
  };

  const handleMenuClose = () => {
    setMenuAnchor(null);
    setSelectedTask(null);
  };

  const handleDeferTask = async (days: number) => {
    if (!selectedTask) return;

    try {
      const task = state.tasks.find(t => t._id === selectedTask);
      if (!task) return;

      const newDueDate = format(addDays(new Date(), days), 'yyyy-MM-dd');
      await axiosInstance.patch(`/api/tasks/${selectedTask}`, {
        dueDate: newDueDate,
        deferCount: (task.deferCount || 0) + 1
      });

      setState(prev => ({
        ...prev,
        tasks: prev.tasks.map(t => 
          t._id === selectedTask 
            ? { ...t, dueDate: newDueDate, deferCount: (t.deferCount || 0) + 1 } 
            : t
        )
      }));

      handleMenuClose();
      fetchTasks();
    } catch (error) {
      console.error('Error deferring task:', error);
      setState(prev => ({
        ...prev,
        error: 'Failed to defer task'
      }));
    }
  };

  const fetchTasks = async () => {
    if (!state.personnelId) return;

    try {
      const response = await axiosInstance.get<TaskItem[]>('/api/tasks', {
        params: {
          assignedTo: state.personnelId,
          completed: false
        }
      });

      setState(prev => ({
        ...prev,
        tasks: response.data.sort((a, b) => {
          if (a.dueDate && b.dueDate) {
            return new Date(a.dueDate).getTime() - new Date(b.dueDate).getTime();
          }
          if (a.urgent !== b.urgent) return a.urgent ? -1 : 1;
          return new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime();
        }),
        loading: false
      }));
    } catch (err) {
      console.error('Error fetching tasks:', err);
      setState(prev => ({
        ...prev,
        error: 'Failed to load tasks',
        loading: false
      }));
    }
  };

  useEffect(() => {
    const fetchPersonnelId = async () => {
      try {
        const response = await axiosInstance.get(`/api/personnel/by-user/${userId}`);
        setState(prev => ({
          ...prev,
          personnelId: response.data._id,
        }));
      } catch (err) {
        console.error('Error fetching personnel ID:', err);
        setState(prev => ({
          ...prev,
          error: 'Failed to load user data',
          loading: false
        }));
      }
    };

    fetchPersonnelId();
  }, [userId]);

  useEffect(() => {
    if (state.personnelId) {
      fetchTasks();
    }
  }, [state.personnelId]);

  const getDeferralSeverity = (count: number): "default" | "warning" | "error" => {
    if (count >= 5) return "error";
    if (count >= 3) return "warning";
    return "default";
  };

  const getDeferralTooltip = (count: number): string => {
    if (count >= 5) return "This task has been deferred many times. Consider addressing it or delegating.";
    if (count >= 3) return "This task has been deferred several times.";
    return `Deferred ${count} time${count !== 1 ? 's' : ''}.`;
  };

  if (state.loading) return <CircularProgress />;
  if (state.error) return <Alert severity="error">{state.error}</Alert>;

  return (
    <Box>
      <Accordion >
        <AccordionSummary expandIcon={<Clock />}>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <Badge badgeContent={state.tasks.length} color="primary">
              <ClipboardList />
            </Badge>
            <Typography>Your Tasks</Typography>
          </Box>
        </AccordionSummary>
        <AccordionDetails>
          <Box sx={{ mb: 2 }}>
            <Button
              variant="contained"
              startIcon={<Plus />}
              onClick={() => setAddTaskOpen(true)}
              size="small"
            >
              Add Task
            </Button>
          </Box>

          {state.tasks.length === 0 ? (
            <Typography color="text.secondary">No tasks assigned to you</Typography>
          ) : (
            <List dense>
              {state.tasks.map((task) => (
                <ListItem
                  key={task._id}
                  button
                  onClick={() => navigate(`/admin/tasks?task=${task._id}`)}
                  sx={{
                    bgcolor: task.urgent ? 'error.main' : 'transparent',
                    '&:hover': {
                      bgcolor: task.urgent ? 'error.dark' : 'action.hover',
                    },
                  }}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={task.completed}
                      onChange={(e) => {
                        e.stopPropagation();
                        handleTaskToggle(task._id);
                      }}
                    />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
                        <Typography
                          sx={{
                            color: task.urgent ? 'common.white' : 'text.primary',
                          }}
                        >
                          {task.text}
                        </Typography>
                        {task.deferCount > 0 && (
                          <Tooltip title={getDeferralTooltip(task.deferCount)}>
                            <Chip
                              size="small"
                              color={getDeferralSeverity(task.deferCount)}
                              icon={<History size={14} />}
                              label={task.deferCount}
                            />
                          </Tooltip>
                        )}
                      </Box>
                    }
                    secondary={
                      <Box sx={{ display: 'flex', gap: 1, alignItems: 'center' }}>
                        {task.urgent && (
                          <Chip 
                            size="small" 
                            color="error" 
                            label="Urgent"
                            sx={{ bgcolor: 'error.dark' }}
                          />
                        )}
                        {task.dueDate && (
                          <Typography 
                            variant="caption"
                            sx={{
                              color: task.urgent ? 'common.white' : 'text.secondary',
                            }}
                          >
                            Due: {format(new Date(task.dueDate), 'PP')}
                          </Typography>
                        )}
                      </Box>
                    }
                  />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      onClick={(e) => handleMenuOpen(e, task._id)}
                      sx={{
                        color: task.urgent ? 'common.white' : 'action.active',
                      }}
                    >
                      <MoreVertical size={16} />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              ))}
            </List>
          )}

<Menu
            anchorEl={menuAnchor}
            open={Boolean(menuAnchor)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => handleDeferTask(1)}>
              <Calendar size={16} style={{ marginRight: 8 }} />
              Defer to tomorrow
            </MenuItem>
            <MenuItem onClick={() => handleDeferTask(3)}>
              <Calendar size={16} style={{ marginRight: 8 }} />
              Defer 3 days
            </MenuItem>
            <MenuItem onClick={() => handleDeferTask(7)}>
              <Calendar size={16} style={{ marginRight: 8 }} />
              Defer 1 week
            </MenuItem>
          </Menu>
        </AccordionDetails>
      </Accordion>

      <TaskDialog 
        open={addTaskOpen}
        onClose={() => setAddTaskOpen(false)}
      />
    </Box>
  );
};

export default TaskList;